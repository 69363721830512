// Polyfills
import 'core-js/fn/array'
import 'core-js/fn/object'
import 'core-js/es6/map'
import 'core-js/es6/'
import 'raf/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { Provider, connect } from 'react-redux'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import reducers from './reducers'
import history from './utils/history'

import '../node_modules/bootstrap/dist/css/bootstrap.css'


// Templates
import Home from './components/Home'
import Users from './components/Admin/Users'
import ViewUser from './components/Admin/ViewUser'

import {
  detectLoginStatus,
} from './actions/user'



// Styling
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import 'react-credit-cards/es/styles-compiled.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css'

/**
 * FancyBox init
 */
window.jQuery = require('jquery')
window.fancybox = require('@fancyapps/fancybox')


const store = createStore(
	combineReducers( reducers ), 
	composeWithDevTools({ trace: true, traceLimit: 25 } )( applyMiddleware( thunk ) )
)

class AppContainer extends React.Component {

	componentDidMount() {
		 this.props.detectLoginStatus()
	}

	render() {

	
		return (
			<Router history={history}>
				<Switch>

					<Redirect from="/index.html" to="/" />
					<Route exact path="/" component={Home} />
					<Route path="/register" component={Home} />

					{ this.props.user.objectId &&
						
						<Switch>

							{ this.props.user.isAdmin &&
								<Route path="/users" component={Users} />
							}
						

							<Route path="/:username" component={ViewUser} />
						
						</Switch>
					}
				
			
				</Switch>
			</Router>
		)
	}

}

const App = connect(
	state => ({ user: state.user }),
	{ 
		 detectLoginStatus,
	}
)( AppContainer )

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('app')
)