import React from 'react'
import { connect } from 'react-redux';
import {  
  Form, 
  Input, 
  InputGroupAddon, 
  InputGroup,
  InputGroupText,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Button
} from 'reactstrap';

import { 
  SingleDatePicker
} from 'react-dates';

import { qualificationList } from '../../../constants'

//actions
import {
  createUser,
  displayCreateUser
} from '../../../actions/admin'


class CreateUser extends React.Component {

  state = {
    email:'',
    password:'',
    firstName:'',
    lastName:'',
    phoneNumber:'',
    address:'',
    postCode:'',
    nino:'',
    cscs:'',
    qualification:[],
    status:'',
    paymentType:'full',
    amountPaid:'',
    amountDue:'',
    fullPayment:'',
    paymentMethod:'cash',
    username: '',
    middleName:'',
    intermediar:'',
    houseNumber:'',
  }

  handleChange = e => {

    let { name, value } = e.target
    let { qualification } = this.state

    if( name === 'qualification' ) {
      
      let old  = qualification

      if( old.includes( value ) )
        old.splice( old.indexOf( value ), 1);  
      else
        old.push( value )

      value = old

    }

    this.setState({
      [name]: value,
    })
    
  }

  onCreateUser = () => {

    let { 
      email,
      date,
      password,
      firstName,
      lastName,
      phoneNumber,
      address,
      postCode,
      nino,
      cscs,
      qualification,
      status,
      paymentType,
      amountPaid,
      amountDue,
      fullPayment,
      paymentMethod,
      username,
      middleName,
      houseNumber,
      intermediar
    } = this.state

    date = date.toDate()
    let day = date.getDate()
    let mointh = date.getMonth()+1
    let year = date.getFullYear()
    
    let dateOfBirth = `${day < 10 ? '0'+day : day }/${mointh < 10 ? '0'+mointh : mointh}/${year}`
    
    
    let user = {
      email,
      password,
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      address,
      postCode,
      houseNumber,
      nino,
      cscs,
      qualification,
      status,
      paymentType,
      amountPaid,
      amountDue,
      fullPayment,
      paymentMethod,
      username,
      middleName,
      intermediar
    }

    this.props.createUser( user )


  }


  render(){

    let { 
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      address,
      houseNumber,
      postCode,
      nino,
      cscs,
      qualification,
      username,
      status,
      middleName,
      intermediar
    } = this.state

    return(
      <div className="popup">
      <div className="content-box">

        <div className="topBar">
          <div className="title">
            <i className="fas fa-user"/>
            <span> CREATE USER </span>
          </div>
          
          <button onClick={ ()=> this.props.displayCreateUser( false ) }> 
            <i className="fas fa-times"/>
          </button>
        </div>

        <Form>


        <Row form>
          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>First Name  * </InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ firstName }
                autoComplete='off'
                onChange={ e => this.setState({ firstName: e.target.value }) }
              />
            </InputGroup>
          </Col>

          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText> Middle Name </InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ middleName }
                autoComplete='off'
                onChange={ e => this.setState({ middleName: e.target.value }) }
              />
            </InputGroup>
          </Col>
        </Row>  

        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText> Last Name  *  </InputGroupText>
          </InputGroupAddon>
          <Input 
            value={ lastName }
            autoComplete='off'
            onChange={ e => this.setState({ lastName: e.target.value }) }
          />
        </InputGroup>

        <br />

        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Username * </InputGroupText>
          </InputGroupAddon>
          <Input 
            value={ username }
            autoComplete='off'
            onChange={ e => this.setState({ username: e.target.value }) }
          />
        </InputGroup>

        <br />

        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Email * </InputGroupText>
          </InputGroupAddon>
          <Input
            name ='email'
            type='email'
            x-autocompletetype='email'  
            value={ email }
            autoComplete='off'
            onChange={ e => this.setState({ email: e.target.value }) }
          />
        </InputGroup>
        
        <br />

    
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Password * </InputGroupText>
          </InputGroupAddon>
          <Input 
            value={ password }
            autoComplete='new-password'
            type='password'
            onChange={ e => this.setState({ password: e.target.value }) }
          />
        </InputGroup>
        
        <br />

        

        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Date of Birth * </InputGroupText>
          </InputGroupAddon>
          <SingleDatePicker
             
            date={ this.state.date } // momentPropTypes.momentObj or null
            onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
            isOutsideRange={() => false}
            
            focused={this.state.focused} // PropTypes.bool
            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
            displayFormat="DD/MM/YYYY"
            id="date_piker_create_user" // PropTypes.string.isRequired,
          />
        </InputGroup>

        <br />

        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Phone Number * </InputGroupText>
          </InputGroupAddon>
          <Input 
            value={ phoneNumber }
            autoComplete='off'
            onChange={ e => this.setState({ phoneNumber: e.target.value }) }
          />
        </InputGroup>

        <br />

        <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Address </InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ address }
                autoComplete='off'
                onChange={ e => this.setState({ address: e.target.value }) }
              />
            </InputGroup>

            <br />
            
        <Row form>
          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>House Number </InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ houseNumber }
                autoComplete='off'
                onChange={ e => this.setState({ houseNumber: e.target.value }) }
              />
            </InputGroup>
          </Col>

          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Post Code </InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ postCode }
                autoComplete='off'
                onChange={ e => this.setState({ postCode: e.target.value }) }
              />
            </InputGroup>
          </Col>
        </Row>

         <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Intermediar </InputGroupText>
          </InputGroupAddon>
          <Input 
            value={ intermediar }
            autoComplete='off'
            onChange={ e => this.setState({ intermediar: e.target.value }) }
          />
        </InputGroup>
        
        <br />  
 
        <Row form>
          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText> NINO </InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ nino }
                autoComplete='off'
                onChange={ e => this.setState({ nino: e.target.value }) }
              />
            </InputGroup>
          </Col>

          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>CSCS  </InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ cscs }
                autoComplete='off'
                onChange={ e => this.setState({ cscs: e.target.value }) }
              />
            </InputGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label>  Qualification * </Label>
          
          <InputGroup>
            <FormGroup className="multipleCheckBox">
            { qualificationList.map( ( item, index ) => {
                 return <CustomInput
                  id={ item }
                  key={ index }
                  value={ item } 
                  type="checkbox"
                  name="qualification"
                  label={ item }  
                  checked={ qualification.includes( item ) }
                  onChange={ e => this.handleChange( e ) }
                />
            })

            }
             
            </FormGroup>
          </InputGroup>
            
        </FormGroup>

        <br />

        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Status * </InputGroupText>
          </InputGroupAddon>
          <Input type="select" name="status" id="exampleSelect" value={ status } onChange={ this.handleChange }>
            <option value="" > Select status </option>
            <option value="missing" >Missing</option>
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
          </Input>
        </InputGroup>

        <br />

        {/* <Row form>
          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Payment Type * </InputGroupText>
              </InputGroupAddon>
              <Input type="select" name="paymentType"  value={ paymentType } onChange={ this.handleChange }>
                 <option value="full">Full</option>
                  <option value="partial" >Partial</option>
              </Input>
            </InputGroup> 
          </Col> 

          <Col md={6}>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Payment Method * </InputGroupText>
                </InputGroupAddon>
                <Input type="select" name="paymentMethod" value={ paymentMethod } onChange={ this.handleChange }>
                  <option value="cash" > Cash </option>
                  <option value="card"> Card </option>
                  <option value="transfer"> Transfer </option>
                </Input>
              </InputGroup>
          </Col>
        </Row>  
   

        { paymentType !== '' ? paymentType === 'partial' ? 
          <Row form>
          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText> Amount Paid </InputGroupText>
                <InputGroupText><span>&#163;</span></InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ amountPaid }
                onChange={ e => this.setState({ amountPaid: e.target.value }) }
              />
            </InputGroup>
          </Col>

          <Col md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText> Amount Due  </InputGroupText>
                <InputGroupText><span>&#163;</span></InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ amountDue }
                onChange={ e => this.setState({ amountDue: e.target.value }) }
              />
            </InputGroup>
          </Col>
        </Row>

        :

          <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText> Full Amount Paid  </InputGroupText>
                <InputGroupText><span>&#163;</span></InputGroupText>
              </InputGroupAddon>
              <Input 
                value={ fullPayment }
                onChange={ e => this.setState({ fullPayment: e.target.value }) }
              />
            </InputGroup>
        :
          null    
        
        } */}

        </Form>

        <br />

        <Button 
          outline
          color="info"
          onClick={ this.onCreateUser } 
        >
            CREATE
        </Button>
        
      </div>

    </div>
    )
  }
}

export default connect(
  state=>({
    utils: state.utils.displayCreateUser
  }),{
    createUser,
    displayCreateUser
  }
) ( CreateUser )