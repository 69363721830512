import statistics from './statistics'
import profiles from './profiles'
import utils from './utils'
import user from './user'
import transactions from './transactions'
import register from './register'


export default {
  transactions,
  statistics,
  profiles,
  register,
  utils,
  user
}