import React from 'react'
import { connect } from 'react-redux'


import { LANG } from '../../../../constants'

// actions
import {
  changeLanguage,
  handleStepsInputs,
  firstStepValidation
} from '../../../../actions/user'


class Step1 extends React.Component {



  handleInputChange = e => {
    
    this.props.handleStepsInputs( e )

  }

  handleOnNext = () => {

    this.props.firstStepValidation( )

  }

  
  render() {

    let { defaultLang, isPending, 
      user :{ email, password, rePassword, username, phoneNumber }
    } = this.props.register

    return(
     <div className="box-form">

      <div className="flagsBox">
        <div className="flag" onClick={() => this.props.changeLanguage('ro')}>
          <img src="/assets/images/ro.png" alt='ro' />
        </div>

        <div className="flag" onClick={() => this.props.changeLanguage('eng')}>
          <img src="/assets/images/uk.png" alt='uk' />
        </div>

      </div>

      <div className="title">
        Register
      </div>

      <input 
        placeholder={LANG[defaultLang].username}
        type="text"
        name="username"
        autoComplete="off"
        value={username}
        maxLength={20}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].email}
        type="email"
        name="email"
        autoComplete="off"
        maxLength={40}
        value={email}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].password}
        type="password"
        name="password"
        maxLength={30}
        autoComplete='new-password'
        value={password}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].rePassword}
        type="password"
        name="rePassword"
        autoComplete='new-password'
        maxLength={30}
        value={rePassword}
        onChange={this.handleInputChange}
      />
  
      <input 
        placeholder={LANG[defaultLang].phoneNumber}
        type="text"
        name="phoneNumber"
        autoComplete="off"
        maxLength={15}
        value={phoneNumber}
        onChange={this.handleInputChange}
      />
  
      <button className="button" onClick={this.handleOnNext}>
        { isPending ? <i className="fad fa-spin fa-spinner"></i> : 'Next Step'}
      </button>

      <hr/>

      <div className="button-change" onClick={()=>this.props.history.push('/')}>
        Already a customer? Login
      </div>

     </div>
    )
  }
}

export default connect(
  state=>({
    user: state.user,
    register: state.register,
  }),{
    changeLanguage,
    handleStepsInputs,
    firstStepValidation
  }
) ( Step1 )