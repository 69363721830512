import {
  serverTime,
  firestore,
  storage,
  fieldValue,
} from '../utils/firebase'

import axios from 'axios'

import md5 from 'md5'


import {
  fetchProfileById
} from './user'

import {
  VALIDATOR,
  SIMPLE_CHARGE,
  SUBSCRIPTION,
  CUSTOM_SUBSCRIPTION,
  STRIPE_CREATE_CLIENT,
  STRIPE_ADD_CARD
} from '../constants'


let ref = {
  notes: firestore.collection('Notes')
}
// const stripe = require('stripe')('sk_test_JO1FTJhGdpxLwhLbACrvJi8M00SwAWyy3U');
/**
 * Message
 * @param {string} message Error message
 */
function errorMessage (message)   {
  alert( message )
	return { type: 'USER_ACTION_ENDED' }
}



export const createUser = userInfo => async ( dispatch, getState ) => {

  let admin = getState().user

	let email = userInfo.email.trim()
	let password = userInfo.password
  let firstName = userInfo.firstName.trim()
  let lastName = userInfo.lastName.trim()
  let middleName = userInfo.middleName.trim()
  let dateOfBirth = userInfo.dateOfBirth
  let phoneNumber = userInfo.phoneNumber
  let status = userInfo.status
  let nino = userInfo.nino.trim()
  let cscs = userInfo.cscs.trim()
  let qualification = userInfo.qualification
  let paymentType = userInfo.paymentType
  let amountPaid = 0
  let amountDue = 0
  let fullPayment = 0
  let paymentMethod = userInfo.paymentMethod
  let username = userInfo.username
  let address = userInfo.address
  let postCode = userInfo.postCode
  let intermediar = userInfo.intermediar
  let houseNumber = userInfo.houseNumber

  
  
  
  if( !email.length || !username.length ||  !password.length || !firstName.length || !lastName.length || !dateOfBirth.length || !phoneNumber.length || !qualification.length || !status.length || !paymentType.length )
    return dispatch( errorMessage( 'Please fill in all required fields' ) )

  // EMAIL VALIDATION
	if ( !VALIDATOR.email.exp.test( email ) )
    return dispatch( errorMessage( 'The specified email is not valid' ) )
  
  // PASSWORD VALIDATION
	if ( !VALIDATOR.password.exp.test( password ) )
    return dispatch( errorMessage( `Your password must be between ${VALIDATOR.password.length[0]} and ${VALIDATOR.password.length[1]} characters long` ) )

  //FIRST NAME VALIDATION  
  if ( !VALIDATOR.name.exp.test( firstName ) )
    return dispatch( errorMessage( `First Name must be between ${VALIDATOR.name.length[0]} and ${VALIDATOR.name.length[1]} characters long` ) )

  //FIRST NAME VALIDATION  
  if ( !VALIDATOR.name.exp.test( lastName ) )
    return dispatch( errorMessage( `Last Name must be between ${VALIDATOR.name.length[0]} and ${VALIDATOR.name.length[1]} characters long` ) )

  //PHONE NUMBER VALIDATION
  if ( !VALIDATOR.phoneNumber.exp.test( phoneNumber ) )
    return dispatch( errorMessage( `Invalid phone number format! `) ) 

  //DATE OF BIRTH VALDIATION
  if ( !VALIDATOR.dob.exp.test( dateOfBirth ) )
    return dispatch( errorMessage( `Invalid Date of Birth fromat! Correct format: DD/MM/YYYY` ) )
  
  // USERNAME VALIDATION
  if ( !VALIDATOR.username.exp.test( username ) )
		return dispatch( errorMessage( `Your username must be between ${VALIDATOR.username.length[0]} and ${VALIDATOR.username.length[1]} characters (letters, numbers or underscores)` ) )
  
  let promise =  await firestore.collection('Users').limit(1).where( 'username', '==', username ).get()
  
  if( !promise.empty ) 
    return dispatch( errorMessage( 'Username already exists' ))
  
  //NINO  VALIDATION
  // eslint-disable-next-line  
  if ( nino.length  && !VALIDATOR.nino.exp.test( nino ) )
    return dispatch( errorMessage( `NINO must be have  ${VALIDATOR.nino.length[0]} characters long` ) )
  
  //CSCS  VALIDATION
  // eslint-disable-next-line  
  if ( cscs.length && !VALIDATOR.cscs.exp.test( cscs ) )
  return dispatch( errorMessage( `CSCS must be have  ${VALIDATOR.cscs.length[0]} characters long, and just numbers` ) )

  // //PAYMENT VALIDATION
  // // eslint-disable-next-line  
  // if( paymentType === 'partial' && ( !amountPaid.length || !amountDue.length ) || paymentType === 'full' && !fullPayment.length )
  //   return dispatch( errorMessage( 'Please fill the payment amount' ))
  // // eslint-disable-next-line  
  // if( paymentType === 'partial' && ( !VALIDATOR.price.exp.test( amountPaid )  || !VALIDATOR.price.exp.test( amountDue )  ) || paymentType === 'full' && !VALIDATOR.price.exp.test( fullPayment )  )
  //   return dispatch( errorMessage( 'Invalitd payment amount format!' ))

  // if( !paymentMethod.length )
  //   return dispatch( errorMessage( 'Please select a payment method' ))  



  fetch('https://us-central1-qmb-db.cloudfunctions.net/onCreateUser', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':  'http://localhost:3000',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials':'true'
    },
    body: JSON.stringify({
      email: email,
      password: password,
    })
  }).then((response) => response.json())
  .then( async res  => {
    
    if( res.error ) 
      return dispatch( errorMessage( res.error.message ) )

      userInfo.objectId = res.uid
      userInfo.createdAt = serverTime
      userInfo.createdBy = admin.objectId
  
     await firestore
        .collection('Users')
        .doc( res.uid )
        .set({
          objectId: res.uid,
          createdAt: serverTime,
          createdBy: admin.objectId,
          firstName: toTitleCase( firstName ),
          lastName: toTitleCase( lastName ),
          middleName: toTitleCase( middleName ),
          email,
          dateOfBirth,
          phoneNumber,
          status,
          nino,
          cscs,
          qualification,
          paymentType,
          amountPaid,
          amountDue,
          fullPayment,
          paymentMethod,
          username,
          address,
          postCode,
          intermediar,
          houseNumber,
          files:{
            
            application: {
              status: 'missing'
            },
            documents: {
              status: 'missing'
            },
            photos: {
              status: 'missing'
            },
            videos: {
              status: 'missing'
            },
          }

        })  
        .catch(  message  => dispatch( errorMessage( message ) ) ) 
  
        dispatch( displayCreateUser( false ) )
  
        dispatch( updateStatistics( status  ) )
  
        dispatch( fetchUsersTable() )
    
  })
  .catch((error) => {
    console.error(error);
  });

}

export const editUser = ( user = null ) => dispatch => {
  
  dispatch({
    type:'DISPLAY_EDIT_USER',
    payload: user === null ? false : true 
  })

  dispatch({
    type: 'SELECTED_USER',
    payload: user === null ? {} : user  
  })

} 


export const addNote = ( user, note ) => async  dispatch => {

  let objectId = user.objectId

  let timestamp = Date.now()
  note.createdAt = timestamp
  note.userId = objectId
  
  let newNote = await ref
  .notes
  .add( note ) 

  ref
  .notes
  .doc( newNote.id )
  .update({ 
    objectId: newNote.id
   }) 
  

}

export const updateNote = ( user, note ) => ( dispatch , getState ) => {

  let timestamp = Date.now()
  note.editedAt = timestamp
  note.editedBy = getState().user.objectId

  ref
  .notes
  .doc( note.objectId )
  .update( note )
  .catch(  message  => dispatch( errorMessage( message ) ) ) 

}

export const updateNoteStatus = ( noteId, status ) => dispatch=> {


  ref
  .notes
  .doc( noteId )
  .update({
    status
    })
  .catch(  message  => dispatch( errorMessage( message ) ) ) 

}

export const updateUser = ( user, name, value ) => dispatch => {

  let objectId = user.objectId

  //FIRST NAME VALIDATION  
  if ( name === 'firstName' && !VALIDATOR.name.exp.test( value ) )
    return dispatch( errorMessage( `First Name must be between ${VALIDATOR.name.length[0]} and ${VALIDATOR.name.length[1]} characters long` ) )

  //FIRST NAME VALIDATION  
  if ( name === 'lastName' && !VALIDATOR.name.exp.test( value ) )
    return dispatch( errorMessage( `Last Name must be between ${VALIDATOR.name.length[0]} and ${VALIDATOR.name.length[1]} characters long` ) )

  //PHONE NUMBER VALIDATION
  if ( name === 'phoneNumber' && !VALIDATOR.phoneNumber.exp.test( value ) )
    return dispatch( errorMessage( `Invalid phone number format! `) ) 

  //DATE OF BIRTH VALDIATION
  if ( name === 'dateOfBirth' &&  !VALIDATOR.dob.exp.test( value ) )
    return dispatch( errorMessage( `Invalid Date of Birth fromat! Correct format: DD/MM/YYYY` ) )
  
  //NINO  VALIDATION
  if ( name === 'nino' &&  value.length  && !VALIDATOR.nino.exp.test( value ) )
    return dispatch( errorMessage( `NINO must be between ${VALIDATOR.nino.length[0]} characters long` ) )
  
  //CSCS  VALIDATION 
  if ( name === 'cscs' &&  value.length && !VALIDATOR.cscs.exp.test( value ) )
    return dispatch( errorMessage( `CSCS must contains just numbers` ) )


  // // //PAYMENT VALIDATION
  // if( name === 'amountPaid' &&  !VALIDATOR.price.exp.test( value )  )
  //   return dispatch( errorMessage( 'Invalitd payment amount format!' ))

  // if( name === 'amountDue' &&  !VALIDATOR.price.exp.test( value )  )
  //   return dispatch( errorMessage( 'Invalitd payment amount format!' ))
  
  // if( name === 'fullPayment' &&  !VALIDATOR.price.exp.test( value )  )
  //   return dispatch( errorMessage( 'Invalitd payment amount format!' ))


  if ( name === 'email' || name === 'password' ) {
    fetch('https://us-central1-qmb-db.cloudfunctions.net/onUpdateUser', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':  'http://localhost:3000',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials':'true'
      },
      body: JSON.stringify({
        name: name,
        val: value,
        uid: objectId,
      })
    }).then((response) => response.json())
    .then(( res ) => {
      
      if( res.error ) 
        return dispatch( errorMessage( res.error.message ) )

    
        firestore
          .collection('Users')
          .doc( objectId )
          .update({
            [name]:value
          })  
          .catch(  message  => dispatch( errorMessage( message ) ) ) 
    
    })
    .catch((error) => {
      console.error(error);
    });

  } else {

    if( name === 'firstName' || name === 'middleName' || name === 'lastName')
      value = toTitleCase( value )
    
    firestore
    .collection('Users')
    .doc( objectId )
    .update({
      [name]: value
    })  
    .catch(  message  => dispatch( errorMessage( message ) ) ) 

    if ( name === 'status' )
      dispatch( updateStatistics( value , user.status ) )

  }
    

    

      // dispatch({
      //   type:'DISPLAY_EDIT_USER',author
      //   payload: false
      // })

      // if ( status !== old_status ) 
      //   dispatch( updateStatistics( status , old_status ) )
      

      // dispatch( fetchUsersTable() )
      

}


export const updateStatistics = ( current, old = null ) => dispatch => {

  firestore
  .collection('Admin')
  .doc('statistics')
  .get()
  .then( snapshot => {
     
      let data = snapshot.data()

      if ( old !== null  )
        data[ old ] = data[ old ] - 1

      if( current !== null )  
        data[ current ] = data[ current ] + 1

      firestore
      .collection('Admin')
      .doc('statistics')
      .update( data )
      .then( ()=> dispatch( fetchStatistics() ) )
      .catch(  message  => dispatch( errorMessage( message ) ) ) 

    
  }) 

}

export const fetchUsersTable = ( action = null ) => async ( dispatch, getState ) => {

  let { search } = getState().utils
 
  dispatch( userTablePending( true ) )

  let query =  firestore.collection("Users")
  

  if ( search.by.length ) {

    if ( search.by === 'status' ) {

      if( search.word.startDate && search.word.endDate )
        query = query.where( 'createdAt', '>=', search.word.startDate ).where( 'createdAt', '<=', search.word.endDate )  
    
        query = query.where('status', '==', search.word.status ) 

    } else if ( search.by === 'firstName' || search.by === 'lastName' || search.by === 'intermediar' ) {

        let word = toTitleCase( search.word )

        query = query.where( search.by , '>=', word ).where( search.by , '<', prefixSuccessor( word ) ).orderBy( search.by, 'asc' )

    } else if ( search.by === 'dateOfBirth') {

      query = query.where( search.by , '==', search.word )

    } else if ( search.by === 'phoneNumber' ) {

      let word = search.word

      query = query.where( search.by , '==', word )

   } else if ( search.by === 'qualification' ) {

    let word = search.word

    query = query.where( search.by , 'array-contains', word )

 } 
     
  }

  query = query.orderBy( 'createdAt', 'desc' ).limit(10)

  if ( action === 'next' )  {
    
    
    let pageHistory = getState().utils.pageHistory
  
    query = query.startAfter( getState().utils.previousDoc.last )
      
    dispatch({
      type: 'UPDATE_PAGE_HISTORY',
      payload: {
        pageHistory,
        back: getState().utils.previousDoc
      }
    })
    
  } else if ( action === 'back' ) {
  
    let pageHistory = getState().utils.pageHistory
    
    query = query.startAt( pageHistory.back.first  )
  
    dispatch({
      type: 'UPDATE_PAGE_HISTORY',
      payload: pageHistory.pageHistory
    })
  
  }

  
 

  query.get().then((snap) => {
    
    const items = {}

    snap.forEach(item => {
     items[item.id] =  item.data()
    })

    let previousDoc = snap.docs[ snap.docs.length - 1 ]
     
    dispatch({
      type: 'PREVIOUS_DOC',
      payload: {
        first: snap.docs[ 0 ],
        last: snap.docs[ snap.docs.length - 1 ]
      }
    }) 

    dispatch({
      type: 'TABLE_USERS_LOADED',
      payload: items
    })

 
    dispatch({
      type:'LAST_PAGE',
      paylaod: snap.empty
    })
    

    if( Object.values( items ).length > 0 )
      dispatch( checkNextExist( previousDoc ))
    else  
      dispatch( userTablePending( false ) ) 
   
  })    
 
    
}




const userTablePending = val => dispatch => {

  dispatch({
    type:'USER_TABLE_PENDING',
    payload: val
  })

}


const notesPending = val => dispatch => {

  dispatch({
    type:'NOTES_PENDING',
    payload: val
  })

}


export const searchUser = ( searchWord ,searchBy ) => dispatch => {


  // if( !searchBy.length )
  //   return dispatch( errorMessage( `Select a type of search` ) )

    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        word:  searchWord,
        by: searchBy
      }
    })

    dispatch({
      type: 'UPDATE_PAGE_HISTORY',
      payload: {
        back: null
      }
    })
    
    dispatch( fetchUsersTable(  ) )

}

export const checkNextExist = previousDoc  =>  ( dispatch, getState ) => {

  let { search } = getState().utils


  let query = firestore.collection("Users")
   

   if ( search.by.length ) {

    if ( search.by === 'status' ) {

      if( search.word.startDate && search.word.endDate )
        query = query.where( 'createdAt', '>=', search.word.startDate ).where( 'createdAt', '<=', search.word.endDate )  
    
        query = query.where('status', '==', search.word.status ) 

    } else if ( search.by === 'firstName' || search.by === 'lastName' ) {

        let word = toTitleCase( search.word )

        query = query.where( search.by , '>=', word ).where( search.by , '<', prefixSuccessor( word ) ).orderBy( search.by, 'asc' )

    } else if ( search.by === 'dateOfBirth') {

      query = query.where( search.by , '==', search.word )

    } else if ( search.by === 'qualification' ) {

      let word = search.word
  
      query = query.where( search.by , 'array-contains', word )
  
   } 
     
  }

  query = query.orderBy( 'createdAt', 'desc' ).startAfter( previousDoc ).limit(1)
  

   query
   .get().then( snapshot => {
  
      dispatch({
        type:'LAST_PAGE',
        payload: snapshot.empty ? true :false
      })

      dispatch( userTablePending( false ) )

  }).catch( error => console.log( error )) 

}

export const displayCreateUser = val => dispatch => {

  dispatch({
    type:'DISPLAY_CREATE_USER',
    payload: val
  })

}


export const viewUser = ( user , history ) => dispatch => {

  dispatch({
    type: 'SELECTED_USER',
    payload: user
  })
  
  history.push('/view-user')

}


export const uploadUserFile = ( userId, category, files ) => async dispatch => {

  if ( !userId )
    return dispatch( errorMessage( `User doesn't exist!` ) )

  if ( !files.length )
    return dispatch( errorMessage( `Please select a file!` ) )      

  if ( !category.length )
      return dispatch( errorMessage( `Please select a category!` ) )  

  dispatch({
    type:'UPLOAD_FILE_PENDING',
    paylaod: true
  })    

  const promises = Object.values( files ).map( async file => {

  let file_name = file.name

  let storageRef = storage.ref( 'Users' ).child( userId ).child( category )

  let uploadFile = storageRef.child( file_name )

  await uploadFile
  .put( file )
  .then( snapshot => {
    uploadFile.getDownloadURL().then( url => {
    
      let file_name_no_ext = file_name.split('.')[0]

      firestore
      .collection( 'Users' )
      .doc( userId )
      .set({
        files: {
          [category]:{
            [file_name_no_ext]:{
              objectId: file_name_no_ext,
              fileName: file_name,
              category: category,
              url: url
            }
          }
        }
          
      },{ merge: true } )
      .catch(  message  => dispatch( errorMessage( message ) ) ) 
    })
  })
  .catch(  message  => dispatch( errorMessage( message ) ) ) 

  })

    if( await Promise.all(promises) )
      dispatch({
        type:'UPLOAD_FILE_PENDING',
        paylaod: false
      })
    
}


export const fetchStatistics = () => dispatch => {

  firestore
  .collection('Admin')
  .doc('statistics')
  .get()
  .then( snapshot => {
     
      let data = snapshot.data()

      dispatch({
        type:'STATISTICS_LOADED',
        paylaod: data
      })

      dispatch(  searchUser('','') )
   
  })

}

export const fetchTransactions = () => dispatch => {

  firestore
  .collection('Admin')
  .doc('transactions')
  .get()
  .then( snapshot => {
     
      let data = snapshot.data()

      dispatch({
        type:'TRANSACTIONS_LOADED',
        paylaod: data
      })

   
  })

}



export const deleteUser = ( userId , status ) =>  async dispatch => {


  let user = await firestore.collection('Users').doc( userId ).get()
  user = user.data()
 

  fetch('https://us-central1-qmb-db.cloudfunctions.net/onDeleteUser', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':  'http://localhost:3000',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials':'true'
    },
    body: JSON.stringify({
      uid: userId,
      storage: user.hasOwnProperty('files') ? true : false
    })
  }).then((response) => response.json())
  .then(( res ) => {
    
    if( res.error ) 
      return dispatch( errorMessage( res.error.message ) )
   
    firestore
    .collection("Users")
    .doc( userId )
    .delete()
    .then( async () => {
   
      dispatch( updateStatistics( null, status ) )
          
      dispatch( fetchUsersTable() ) 

      alert( 'User deleted successful!' )

    }).catch((error) =>  console.error(error));

  }).catch((error) =>  console.error(error));

}




export const test = () => dispatch => {



  //RKZhUE7a4dW15Ffkd18BjoTzJdm2
  //console.log('a')
  firestore.collection('Users').get().then( async snapshot => {
    if ( snapshot.empty ) return false
    let items = snapshot.docs.map( doc => doc.data() ) 
     await items.map(  async item => {

     if( !item.hasOwnProperty('notes') )
        return null
  
      await Object.values( item.notes ).map( async note => {
         let newNote =  await firestore.collection('Notes').add({
            ...note,
            userId: item.objectId,
          })

          await firestore.collection('Notes').doc( newNote.id ).update({
            objectId: newNote.id 
          })


       })
      
      })
      console.log('done')
 })

}


/**
 * Method to capitalize each word in a string
 * @param {string} str Text to capitalize
 */
export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const  prefixSuccessor = prefix => {
  // We can increment the last character in the string and be done
  // unless that character is 255 (0xff), in which case we have to erase the
  // last character and increment the previous character, unless that
  // is 255, etc. If the string is empty or consists entirely of
  // 255's, we just return the empty string.
  let limit = prefix;
  while (limit.length > 0) {
    const index = limit.length - 1;
    if (limit[index] === '\xff') {
      limit = limit.slice(0, -1);
    } else {
      limit =
        limit.substr(0, index) +
        String.fromCharCode(limit.charCodeAt(index) + 1);
      break;
    }
  }
  return limit;
}


export const fetchNotes = ( action = null ) => ( dispatch, getState ) => {

  dispatch( notesPending( true )  )

  let query = firestore
  .collection('Notes')
  .orderBy('createdAt', 'desc')
  .limit(10)


  if ( action === 'next' )  {
    
     
    let pageHistory = getState().utils.pageHistoryNotes
  
    query = query.startAfter( getState().utils.previousDocNotes.last )
      
    dispatch({
      type: 'UPDATE_PAGE_HISTORY_NOTES',
      payload: {
        pageHistory,
        back: getState().utils.previousDocNotes
      }
    })
    
  } else if ( action === 'back' ) {
    
    let pageHistory = getState().utils.pageHistoryNotes
    
    query = query.startAt( pageHistory.back.first  )
  
    dispatch({
      type: 'UPDATE_PAGE_HISTORY_NOTES',
      payload: pageHistory.pageHistory
    })
  
  }

  
  query
  .get()
  .then( snapshot => {


    
    dispatch({
      type: 'PREVIOUS_DOC_NOTES',
      payload: {
        first: snapshot.docs[ 0 ],
        last: snapshot.docs[ snapshot.docs.length - 1 ]
      }
    }) 
 
    dispatch({
      type:'LAST_PAGE_NOTES',
      paylaod: snapshot.empty
    })

    let notes= []
    // eslint-disable-next-line 
    snapshot.docs.map( note => {

      note = note.data()
      notes.push( note )

      dispatch( fetchProfileById( note.authorId ) )
      dispatch( fetchProfileById( note.userId ) )

      if( note.hasOwnProperty( 'editedBy' ))
        dispatch( fetchProfileById( note.editedBy ) )

    })


    if( notes.length > 0 )
      dispatch( checkNextNoteExist( snapshot.docs[ snapshot.docs.length - 1 ] ) )
    else  
    dispatch( notesPending( false ) )
   
   
    

    dispatch({
      type:'NOTES_LOADED',
      payload: notes
    })



   
  
  })

}

export const checkNextNoteExist = previousDoc  =>  ( dispatch, getState ) => {

 

  let query = firestore.collection('Notes')
  
   

  query = query.orderBy( 'createdAt', 'desc' ).startAfter( previousDoc ).limit(1)
  

   query
   .get().then( snapshot => {
  
      dispatch({
        type:'LAST_PAGE_NOTES',
        payload: snapshot.empty ? true :false
      })

      dispatch( notesPending( false ) )

  }).catch( error => console.log( error )) 

}




export const createStripeClient = data =>  dispatch => {

  dispatch({type:'ADD_CARD_START_PENDING' })
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':  'http://localhost:3000',
    'Access-Control-Allow-Methods': 'POST',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Credentials':'true' 
  }  

  axios
    .post( STRIPE_CREATE_CLIENT , data, headers )
    .then( res => {

      let message = {
        STRIPE_CREATE_CLIENT_SUCCESS: 'Card added successfully!',
        STRIPE_CREATE_CLIENT_ERROR: 'Card filed to add!'
      }
      dispatch({type:'ADD_CARD_END_PENDING' })
      alert( message[ res.data.status  ] )
  
    })
    .catch( () => {
      dispatch({type:'ADD_CARD_END_PENDING' })
      alert('Woops, Something wrong!')
    })

}


export const addCardToCostumer = data => dispatch => {

  dispatch({type:'ADD_CARD_START_PENDING' })

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':  'http://localhost:3000',
    'Access-Control-Allow-Methods': 'POST',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Credentials':'true' 
  }  

  axios
    .post( STRIPE_ADD_CARD , data, headers )
    .then( res => {

      let message = {
        STRIPE_ATTACH_CARD_SUCCESS: 'Card added successfully!',
        STRIPE_ATTACH_CARD_ERROR: 'Card filed to add!'
      }
      dispatch({type:'ADD_CARD_END_PENDING' })
      alert( message[ res.data.status  ] )
  
    })
    .catch( () => {
      dispatch({type:'ADD_CARD_END_PENDING' })
      alert('Woops, Something wrong!')
    } )

}

export const payWithCard  = ( data, user ) => async dispatch => {

  dispatch({ type:'PAYMENT_START_PENDING'})


  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':  'http://localhost:3000',
    'Access-Control-Allow-Methods': 'POST',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Credentials':'true' 
  } 



  let URL = ''

  if (  data.type === 'installments' && data.plan === 'custom' && data.method === 'card' )
    URL = CUSTOM_SUBSCRIPTION

  else if (  data.type === 'installments' && data.plan !== 'custom' && data.method === 'card' )
    URL = SUBSCRIPTION
  
  else 
    URL = SIMPLE_CHARGE

    console.log(URL)

  axios
    .post( URL, data, headers )
    .then( async res => {


      let total = 0
      let due = 0

      let transactions = await firestore.collection('Admin').doc('transactions').get()
      transactions = transactions.data()

      if ( data.type === 'installments') {

        total = data.amount * data.iteration
        due =  data.amount * data.iteration

        await firestore.collection('Admin').doc('transactions').update({
          total: transactions.total + total,
          due: transactions.due + due
        })

        await firestore.collection('Users').doc( user.objectId ).update({
          amountDue: user.amountDue + due,
          fullPayment: user.fullPayment + total
        })
      

      } else {

        total = parseFloat( data.amount ) 
        due = parseFloat( data.amount ) 

        await firestore.collection('Admin').doc('transactions').update({
          total: transactions.total + total,
          due: transactions.due + due
        })

        await firestore.collection('Users').doc( user.objectId ).update({
          amountDue: user.amountDue + due,
          fullPayment: user.fullPayment + total
        })

      }
     
      let message = {
        STRIPE_CHARGE_SUCCESS: 'Payment Successfully!',
        STRIPE_CHARGE_FILED: 'Payment Filed!',
        STRIPE_SUBSCRIBE_SUCCESS:'Subscribed Successfully!',
        STRIPE_SUBSCRIBE_FILED: 'Subscribed Filed!'
      }
      if( res.data.status )
        alert( message[ res.data.status ] )

      dispatch( updateQualification(data.uid, data.description ))

    })
    .catch( () => {
      alert('Woops, Something wrong!')
      dispatch({ type:'PAYMENT_STOP_PENDING'})
    })

    
}

export const payWithCash = ( data, user )=>  async dispatch => {

   dispatch({ type:'PAYMENT_START_PENDING'})

  let payment = {
    createdAt: new Date().getTime(),
    amount: parseFloat( data.amount ),
    type: data.type,
    method: data.method,
    description: data.description,
    paid: true,
    status:'succeeded',
  }



  let total = 0
  let due = 0

  let transactions = await firestore.collection('Admin').doc('transactions').get()
  transactions = transactions.data()


  if ( data.type === 'installments') {

    let id = md5( data.plan + data.description )

    payment.id = id

    let paymentsHistory = user.paymentsHistory || []

    let check = paymentsHistory.filter(item => item.id ===id )

    if ( !check.length ) {

       total = data.amount * data.iteration
       due =  data.amount * data.iteration
      
      await firestore.collection('Admin').doc('transactions').update({
        total: transactions.total + total,
        due: transactions.due + due
      })

      await firestore.collection('Users').doc( user.objectId ).update({
        amountDue: user.amountDue + due,
        fullPayment: user.fullPayment + total
      })
      
    }

  } else {

    total = parseFloat( data.amount ) 
    due = parseFloat( data.amount ) 

    await firestore.collection('Admin').doc('transactions').update({
      total: data.type ===  'old' ? total : transactions.total + total,
      due: data.type ===  'old' ? transactions.due : transactions.due + due
    })

    await firestore.collection('Users').doc( user.objectId ).update({
      amountDue: data.type ===  'old' ? user.amountDue : user.amountDue + due,
      fullPayment: data.type ===  'old' ? total : user.fullPayment + total
    })

  }
    
  firestore
  .collection('Users')
  .doc( data.uid )
  .update({
    paymentsHistory:fieldValue.arrayUnion( payment ),
    qualification:fieldValue.arrayUnion( data.description )
  }).then( () => {
    alert('Payment Success!')
    dispatch({ type:'PAYMENT_STOP_PENDING'})
  }).catch( error => {
    alert('Payment Failed!')
    dispatch({ type:'PAYMENT_STOP_PENDING'})
  })

    
}


export const updateQualification = ( id, qualification ) =>  dispatch => {

  firestore
  .collection('Users')
  .doc( id )
  .update({
    qualification:fieldValue.arrayUnion( qualification )
  }).then( () => {
    
    dispatch({ type:'PAYMENT_STOP_PENDING'})
  }).catch( error => {
  
    dispatch({ type:'PAYMENT_STOP_PENDING'})
  })

}



export const use = () => dispatch => {

  // console.log( 'AA' )
  // dispatch( calcClientPayment() )
}

export const calcClientPayment = () => dispatch => {
  
  let globalTotal = 0
  let globalPaid = 0
  let globalDue = 0

  firestore.collection('Users').get().then( docs => {

    docs.docs.forEach( async doc => {

      let obj = doc.data()

      // let total = parseFloat( obj.amountPaid || 0 )  + parseFloat( obj.amountDue || 0 ) + parseFloat( obj.fullPayment|| 0 )
      // let paid = parseFloat( obj.fullPayment || 0) + parseFloat(  obj.amountPaid|| 0 )
      // let due = parseFloat( obj.amountDue || 0 ) 

      // globalPaid =  globalPaid + paid
      // globalTotal = globalTotal + total
      // globalDue =  globalDue + due 

      if ( obj.fullPayment > 3000 )
      console.log( obj.firstName + " " + obj.lastName + "  Total: " + obj.fullPayment + " PAID : " + obj.amountPaid + " DUE: " + obj.amountDue )

      // await firestore.collection('Users').doc( obj.objectId ).update({
      //   amountDue: due,
      //   amountPaid: paid,
      //   fullPayment: total
      // })


    })
  }).then( () => {

    console.log( 'TOTAL', globalTotal,  globalPaid, globalDue )

    // firestore.collection('Admin').doc('transactions').update({
    //   paid: globalPaid,
    //   due: globalDue,
    //   total: globalTotal
    // })

    console.log('DONE!!!')
  }).catch( console.log )

}  


export const pay  = ( data, user ) => async dispatch => {

  dispatch({ type:'PAYMENT_START_PENDING'})


  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':  'http://localhost:3000',
    'Access-Control-Allow-Methods': 'POST',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Credentials':'true' 
  } 


  let total = 0
  let due = 0

  let transactions = await firestore.collection('Admin').doc('transactions').get()
  transactions = transactions.data()


  let userNew = await firestore.collection('Users').doc( user.objectId ).get()
  userNew = userNew.data()

  if ( data.type === 'installments') {

    total = data.amount * data.iteration
    due =  data.amount * data.iteration

    await firestore.collection('Admin').doc('transactions').update({
      total: transactions.total + total,
      due: transactions.due + due
    })
  
    await firestore.collection('Users').doc( user.objectId ).update({
      amountDue: userNew.amountDue + due,
      fullPayment: userNew.fullPayment + total
    })
  

  } else {

    total = parseFloat( data.amount ) 
    due = parseFloat( data.amount ) 

    await firestore.collection('Admin').doc('transactions').update({
      total: transactions.total + total,
      due: transactions.due + due
    })
 
    await firestore.collection('Users').doc( user.objectId ).update({
      amountDue: userNew.amountDue + due,
      fullPayment: userNew.fullPayment + total
    })

  }


  await axios
    .post( data.type === 'installments' ? SUBSCRIPTION : SIMPLE_CHARGE , data, headers )
    .then( async res => {

     
      let message = {
        STRIPE_CHARGE_SUCCESS: 'Payment Successfully!',
        STRIPE_CHARGE_FILED: 'Payment Filed!',
        STRIPE_SUBSCRIBE_SUCCESS:'Subscribed Successfully!',
        STRIPE_SUBSCRIBE_FILED: 'Subscribed Filed!'
      }
      if( res.data.status )
        alert( message[ res.data.status ] )

      dispatch( updateQualification(data.uid, data.description ))

    })
    .catch( error => {
      console.log( error )
      alert('Woops, Something wrong!')
      dispatch({ type:'PAYMENT_STOP_PENDING'})
    })

    
}
