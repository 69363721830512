
const initialState = {
	defaultLang:'eng',
	isPending: false,
	currentStep:1,
	user:{
		fullPayment:0,
		amountDue:0,
		amountPaid:0,
		cards:[],
		stats:'missing',
		email:'', 
		password:'', 
		rePassword:'', 
		username:'',
		phoneNumber:'',
		firstName:'',
		middleName:'',
		lastName:'',
		dateOfBirth:'',
		address:'',
		postCode:'',
		intermediar:'',
		nino:'',
		cscs:'',
		houseNumber:'',
		createdAt: new Date(),
		qualification:[],
		files:{
			application:{
				status:'missing'
			},
			documents:{
				status:'missing'
			},
			photos:{
				status:'missing'
			},
			videos: {
				status: 'missing'
			}
		}

	}
}

export default (state = initialState, action) => {
	switch ( action.type ) {

		case 'REGISTER_PENDING_START':
			return {
				...state,
				isPending: true
			}

		case 'REGISTER_PENDING_END':
			return {
				...state,
				isPending: false
			}		

		case 'CHANGE_LANG':
			return {
				...state,
				defaultLang: action.payload
			}

		case 'UPDATE_STEPS_FIELDS':
			return {
				...state,
				user:{
					...state.user,
					[action.payload.name]: action.payload.value
				}
			}
			
		case 'STEP_ONE_VALIDATION': 
			return {
				...state,
				isPending: false,
				currentStep: 2
			}

		case 'STEP_TOW_VALIDATION': 
			return {
				...state,
				isPending: false,
				currentStep: 3
			}
		
		case 'STEP_THREE_VALIDATION':
			return initialState

			
		
		default:
			return state
	}
}