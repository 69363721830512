import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux';
import StackGrid from "react-stack-grid";

//Templetes
import SideMenu from '../../SideMenu'

//actions
import {
  fetchStatistics,
  fetchTransactions,
  searchUser,
  fetchNotes
} from '../../../actions/admin'

import {
  Button
} from 'reactstrap'

import { formatMoney } from '../../../utils'

import Note from './note'


class Welcome extends React.Component {

  componentDidMount() {
    
    let { user:{ objectId }, profiles } = this.props
    let self = profiles[ objectId ]
   

    if ( !self.isAdmin )
      return this.props.history.push(`/${self.username}`)

    this.props.fetchStatistics()
    this.props.fetchTransactions()
    this.props.fetchNotes()

  }

  render(){
     let { isPedning, completed, pending, missing } = this.props.statistics 
     let { transactions } = this.props
     let { notes } = this.props.utils
 
    return(
      <div className="dashboard">
        
        <Route component={SideMenu} />

        <div className="detail">

       
          <div id="statistics" className="box">

            { isPedning ? 
              <div className="spinnerBox">
                <i className="fas fa-spinner fa-spin" />
              </div>
            :
              <div className="groupBtn">
                <div className="btnBox missing"  onClick ={ () => this.props.searchUser( { status: 'missing' },  'status' ) } >
                  <i className="fas fa-chart-line"/>
                  <span className="count"> { missing || 0 } </span>
                  <span className="status"> Missing </span>
                </div>

                <div className="btnBox pending"  onClick ={ () => this.props.searchUser( { status: 'pending' },  'status' )  } >
                  <i className="fas fa-chart-line"/>
                  <span className="count"> { pending || 0 } </span>
                  <span className="status"> Pending </span>
                </div>
            
                <div className="btnBox completed"  onClick ={ () => this.props.searchUser( { status: 'completed' },  'status' )  }>
                  <i className="fas fa-chart-line"/>
                  <span className="count"> { completed || 0 } </span>
                  <span className="status"> Completed </span>
                </div>

              </div>  
            }


            { transactions.isPedning ? 
              <div className="spinnerBox">
                <i className="fas fa-spinner fa-spin" />
              </div>
            :
              <div className="groupBtn">
                <div className="btnBox  completed"    >
                  <i className="fas fa-chart-line"/>
                  <span className="count"><span>&#163;</span>  { formatMoney( transactions.total || 0 ) } </span>
                  <span className="status"> Total </span>
                </div>

                <div className="btnBox completed"  >
                  <i className="fas fa-chart-line"/>
                  <span className="count"> <span>&#163;</span> { formatMoney( transactions.paid || 0 )  } </span>
                  <span className="status"> Paid </span>
                </div>
            
                <div className="btnBox missing "  >
                  <i className="fas fa-chart-line"/>
                  <span className="count">  <span>&#163;</span> { formatMoney( transactions.due || 0 ) } </span>
                  <span className="status"> Due </span>
                </div>

              </div>  
            }

            <div className="content">

              <br />
              { this.props.utils.notesPending ?
                <div className="spinnerBox">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              
              :
                <React.Fragment>
                  <StackGrid
                    columnWidth={ 300 }
                  // gutterHeight={ 5 }
                  >
                    { notes.map( note => {
                        return <Note 
                          key={ note.objectId }
                          history={ this.props.history }
                          data ={ note }
                        />
                      })
                    }
                  </StackGrid>

                  <div className="pagination">
                    { this.props.utils.pageHistoryNotes.back ?
                      <Button outline  color="info" onClick={ ()=>  this.props.fetchNotes( 'back' ) }>
                        <i className="fas fa-chevron-left" />  Back
                      </Button>
                    :
                      <span></span>
                    }
                  
                    { !this.props.utils.lastPageNotes ?
                      <Button outline color="info" onClick={ ()=>  this.props.fetchNotes( 'next' ) }>
                        Next   <i className="fas fa-chevron-right" /> 
                      </Button>
                    :
                      <span></span>
                    }
                  </div>
                </React.Fragment>      

              }

            </div>

          </div>

          </div>

      </div>
    )
  }
}

export default connect(
  state=>({
    statistics: state.statistics,
    transactions: state.transactions,
    utils: state.utils,
    user: state.user,
    profiles: state.profiles
  }),{
    fetchStatistics,
    fetchTransactions,
    searchUser,
    fetchNotes,
    
  }
) ( Welcome )