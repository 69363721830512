import React from 'react'
import { connect } from 'react-redux'


import { LANG } from '../../../../constants'

// actions
import {
  changeLanguage,
  handleStepsInputs,
  finalStepValidation
} from '../../../../actions/user'


class Step2 extends React.Component {



  handleInputChange = e => {
    
    this.props.handleStepsInputs( e )

  }

  handleOnNext = () => {

    this.props.finalStepValidation( )

  }

  componentDidMount() {

    let { currentStep } = this.props.register

    if ( currentStep < 3 )
      this.props.history.push('/register/step2')
  }


  
  render() {

    let { defaultLang, isPending, 
      user :{ nino, cscs }
    } = this.props.register

    return(
     <div className="box-form">

      <div className="flagsBox">
        <div className="flag" onClick={() => this.props.changeLanguage('ro')}>
          <img src="/assets/images/ro.png" alt='ro' />
        </div>

        <div className="flag" onClick={() => this.props.changeLanguage('eng')}>
          <img src="/assets/images/uk.png" alt='uk' />
        </div>

      </div>

      <div className="title">
        Register
      </div>


      <input 
        placeholder={LANG[defaultLang].nino}
        type="text"
        name="nino"
        autoComplete="off"
        maxLength={20}
        value={nino}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].cscs}
        type="text"
        name="cscs"
        maxLength={20}
        autoComplete="off"
        value={cscs}
        onChange={this.handleInputChange}
      />

    
  
     
  
      <button className="button" onClick={this.handleOnNext}>
        { isPending ? <i className="fad fa-spin fa-spinner"></i> : 'Final Step'}
      </button>

      <hr/>

      <div className="button-change" onClick={()=>this.props.history.push('/')}>
        Already a customer? Login
      </div>

     </div>
    )
  }
}

export default connect(
  state=>({
    user: state.user,
    register: state.register,
  }),{
    changeLanguage,
    handleStepsInputs,
    finalStepValidation
  }
) ( Step2 )