import {
	
  firestore,
  auth
} from '../utils/firebase'
import json2csv from "json2csv";


import {
	VALIDATOR 
} from '../constants'

import history from '../utils/history'


let ref = {
	notes: firestore.collection( 'Notes' )
}

let store = {
	users: firestore.collection('Users')
}
const { Parser } = require('json2csv');

function errorMessage (message)   {
  alert( message )
	return { type: 'REGISTER_PENDING_END' }
}


export const profilePending = () => {
  return { type: 'PROFILE_LOADING' }
}

export const fetchProfile = username =>  dispatch => {

  dispatch( profilePending() )
  
  firestore
  .collection("Users")
  .limit(1)
  .where( 'username', '==', username )
  .onSnapshot( snapshot => {

    let profile =  snapshot.empty ? {} : snapshot.docs[0].data()
		
		dispatch({
			type:'PROFILE_LOADED',
			payload: profile
		})

		if( !profile.objectId )
			return

	
		dispatch( fetchProfileById( profile.createdBy ) )

		 ref
		.notes
		.where('userId','==', profile.objectId )
		.orderBy( 'createdAt', 'asc' )
		.onSnapshot( snapshot => {
	
			if ( snapshot.empty )
				return null
			 
			let notes = []
			// eslint-disable-next-line 	
			snapshot.docs.map( note => {

				note = note.data()
				notes.push( note )
				dispatch( fetchProfileById( note.authorId ) )

			})
			
			if( notes.length !== 0 ) {
					dispatch({
						type:'PROFILE_NOTES_LOADED',
						payload: {
							objectId: profile.objectId,
							notes
						}
					})
				
			}

		
			

			

		})
		
  })

}

export const fetchProfileById = uid => ( dispatch, getState ) => {


	let profiles = getState().profiles

	if( profiles[ uid ] )
		return
  
  firestore
  .collection( "Users" )
  .limit(1)
	.where( 'objectId', '==', uid )
	.get()
  .then( snapshot => {

    let profile =  snapshot.empty ? {} : snapshot.docs[0].data()
      
    dispatch({
      type:'PROFILE_LOADED',
      payload: profile
    })

  })

}


export const detectLoginStatus = () => ( dispatch, getState ) => {

	//dispatch( test() )
		
	auth.onAuthStateChanged( account => {

		dispatch( pendingAction() )
		
		if ( !account )
      return dispatch({ type: 'USER_LOGGED_OUT' })
      
      const { uid } = account  
	
		dispatch( fetchUserData( uid ) )
		
	})

}

export const fetchUserData = uid => dispatch => {


		firestore
		.collection("Users")
		.doc( uid )
		.onSnapshot( snapshot => {
		

			if ( !snapshot.exists )
				return dispatch( logout() )
			
			let profile = snapshot.data()

			dispatch({
				type: 'PROFILE_LOADED',
				payload: profile
			})

			dispatch({ 
				type: 'USER_LOGGED_IN', 
				payload: { 
					objectId: uid,
					username: profile.username,
					isAdmin: profile.isAdmin
				
				}
			})

		})


}

export const login = ( email, password ) => dispatch =>{

	email = email.trim()
	password = password.trim()
	
	if ( !email.length || !password.length )
		return loginFailed('Please fill in both fields')

	dispatch( pendingAction() )

	auth.signInWithEmailAndPassword( email, password )
		.catch(({ message }) => dispatch( loginFailed( message ) ))

}

/**
 * Logout method
 */
export const logout = () => () => {
	
	auth.signOut().then( () => document.location = '/' )

}

const pendingAction = () => ({
	type: 'USER_ACTION_PENDING'
})


/**
 * Login failed
 * @param {string} message Error message
 */
function loginFailed( message ) {
	alert( message )
	return { type: 'USER_ACTION_ENDED' }
}


/**
 * Update Steps fields
 * @param {name, value} 
 */

 export const handleStepsInputs = e => dispatch => {

		let { name, value } = e.target
	

		dispatch({
			type:'UPDATE_STEPS_FIELDS',
			payload:{ 
				name: name, 
				value: value 
			}
		})

 }


/**
 * Change default Language
 * @param {string} lang 
 */

 export const changeLanguage = lang => dispatch => {

	dispatch({
		type:'CHANGE_LANG',
		payload:lang
	})

 }


/**
 * Register - First Step Validation
 * @param {obj} data 
 */

export const firstStepValidation = () => async ( dispatch, getState ) => {

	dispatch({ type:'REGISTER_PENDING_START'})

	let { user } = getState().register 

	let username = user.username
	let password = user.password
	let rePassword = user.rePassword
	let email = user.email
	let phoneNumber = user.phoneNumber

	if( !email.length || !username.length ||  !password.length || !rePassword.length || !phoneNumber.length )
    return dispatch( errorMessage( 'Please fill in all fields' ) )

	// EMAIL VALIDATION
	if ( !VALIDATOR.email.exp.test( email ) )
    return dispatch( errorMessage( 'The specified email is not valid' ) )
  
  // PASSWORD VALIDATION
	if ( !VALIDATOR.password.exp.test( password ) )
    return dispatch( errorMessage( `Your password must be between ${VALIDATOR.password.length[0]} and ${VALIDATOR.password.length[1]} characters long` ) )

	// CHECK PASSWORD MATCH 
	if ( password !== rePassword )
		return dispatch( errorMessage(  'Passwords don\'t match ') )

	// USERNAME VALIDATION
	if ( !VALIDATOR.username.exp.test( username ) )
	 	return dispatch( errorMessage( `Your username must be between ${VALIDATOR.username.length[0]} and ${VALIDATOR.username.length[1]} characters (letters, numbers or underscores)` ) )
	 
	//PHONE NUMBER VALIDATION
  if ( !VALIDATOR.phoneNumber.exp.test( phoneNumber ) )
    return dispatch( errorMessage( `Invalid phone number format! `) ) 		

	// CHECK USERNAME IF EXIST 
	let promise =  await store.users.limit(1).where( 'username', '==', username ).get()

	if( !promise.empty ) 
		return dispatch( errorMessage( 'Username already exists' ))
	
	// CHECK  IF EXIST 
	promise =  await store.users.limit(1).where( 'email', '==', email ).get()

	if( !promise.empty ) 
		return dispatch( errorMessage( 'Email already exists' ))

	dispatch({
		type:'STEP_ONE_VALIDATION',
	})	

	history.push('/register/step2')


}


/**
 * Register - Second Step Validation
 */

export const secondStepValidation = () => async ( dispatch, getState ) => {

	dispatch({ type:'REGISTER_PENDING_START'})

	let { user } = getState().register 

	let firstName = user.firstName
	let lastName = user.lastName
	let dateOfBirth = user.dateOfBirth
	let address = user.address
	let postCode = user.postCode
	let houseNumber = user.houseNumber

	if( !firstName.length  || !lastName.length || !dateOfBirth.length || !address.length || !postCode.length || !houseNumber.length )
    return dispatch( errorMessage( 'Please fill in all fields' ) )

	//DATE OF BIRTH VALDIATION
	if ( !VALIDATOR.dob.exp.test( dateOfBirth ) )
		 return dispatch( errorMessage( `Invalid Date of Birth format! Correct format: DD/MM/YYYY` ) )
		 
	//FIRST NAME VALIDATION  
  if ( !VALIDATOR.name.exp.test( firstName ) )
    return dispatch( errorMessage( `First Name must be between ${VALIDATOR.name.length[0]} and ${VALIDATOR.name.length[1]} characters long` ) )

  //LAST NAME VALIDATION  
  if ( !VALIDATOR.name.exp.test( lastName ) )
    return dispatch( errorMessage( `Last Name must be between ${VALIDATOR.name.length[0]} and ${VALIDATOR.name.length[1]} characters long` ) )	 

	dispatch({
		type:'STEP_TOW_VALIDATION',
	})	

	history.push('/register/step3')


}

/**
 * Register - Final Step Validation
 */

export const finalStepValidation = () => async ( dispatch, getState ) => {

	dispatch({ type:'REGISTER_PENDING_START'})

	let { user } = getState().register 


	 auth.createUserWithEmailAndPassword( user.email, user.password ).then( async snap => {


		let id = snap.user.uid 

		user.objectId = id
		user.createdBy = id
	
		await store.users.doc( id ).set( user )
	
		dispatch({
			type:'STEP_THREE_VALIDATION',
		})	

		history.push('/')

	}).catch( error => {
		dispatch( errorMessage( 'Woops something wrong! Try again latter! ' ) ) 
	})


}


export const test  = () => dispatch => {


	const fields = ['firstName', 'middleName', 'lastName', 'email', 'phoneNumber', 'qualification', 'createdAt'];
	const users = [];
 
	let date = new Date( 2020, 0, 1 ,0,0,0,0,0 )

	let timestamp = date.getTime() / 100
	console.log( )

	firestore
	.collection("Users").get().then( snap => {
		let docs = snap.docs
		
		

		docs.map( item => {

			let doc = item.data()

			if ( new Date( doc.createdAt.toMillis() ) >= date)
					users.push({
						firstName: doc.firstName,
						lastName: doc.lastName,
						middleName: doc.middleName,
						email: doc.email,	
						phoneNumber: doc.phoneNumber,
						qualification: JSON.stringify(doc.qualification),
						createdAt: new Date( doc.createdAt.toMillis() )
					})
					
		})

		const json2csvParser = new Parser({ fields });
const csv = json2csvParser.parse(users);

console.log(csv);
	})
 }


