import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Step1 from './Steps/step1'
import Step2 from './Steps/step2'
import Step3 from './Steps/step3'


class Register extends React.Component {

  
  render() {

    return(
      <div id="register">

        <Switch>
          <Route exact path="/register" component={Step1} />	
          <Route exact path="/register/step2" component={Step2} />
          <Route exact path="/register/step3" component={Step3} />						
        </Switch>

    </div>
    )
  }
}

export default connect(
  state=>({
    user: state.user
  }),{
  
  }
) ( Register )