
const initialState = {
  userTablePending: false,
  notesPending: false,
  lastPage: true,
  lastPageNotes: false,
  displayCreateUser: false,
  displayEditUser: false,
  uploadFilePending: false,
  cardPending:false,
  paymentPending: false,
  selectedUser:{},
  search:{
    word: '',
    by: '',
  },
  pageHistory:{
    back: null
  },
  pageHistoryNotes:{
    back: null
  },
  previousDoc:null,
  previousDocNotes:null,
  tableUsers:{},
  notes:[]
}

export default (state = initialState, action) => {
	switch ( action.type ) {

    case 'NOTES_LOADED':
      return {
        ...state,
        notes: action.payload,
      }
    
    case 'PREVIOUS_DOC_NOTES':
      return {
        ...state,
        previousDocNotes: action.payload
      }

    case 'PREVIOUS_DOC':
      return {
        ...state,
        previousDoc: action.payload
      }

		case 'USER_TABLE_PENDING':
      return {
        ...state,
        userTablePending: action.payload
      }
    
    case 'NOTES_PENDING':
      return {
        ...state,
        notesPending: action.payload
      }  
    
    case 'DISPLAY_EDIT_USER':
      return {
        ...state,
        displayEditUser: action.payload
      }

    case 'TABLE_USERS_LOADED':
      return {
        ...state,
        tableUsers: action.payload
      }
    case 'UPLOAD_FILE_PENDING':
      return {
        ...state,
        uploadFilePending: action.payload
      }
    
    case 'UPDATE_PAGE_HISTORY':
      return {
        ...state,
        lastPage: false,
        pageHistory: action.payload
      }
    
    case 'UPDATE_PAGE_HISTORY_NOTES':
      return {
        ...state,
        lastPageNotes: false,
        pageHistoryNotes: action.payload
      }  
    
    case 'LAST_PAGE_NOTES':
      return {
        ...state,
        lastPageNotes: action.payload,
      }

    case 'LAST_PAGE':
      return {
        ...state,
        lastPage: action.payload,
      }

    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: action.payload
      }
    
    case 'DISPLAY_CREATE_USER':
      return {
        ...state,
        displayCreateUser: action.payload
      }
      
    case 'SELECTED_USER':
      return {
        ...state,
        selectedUser: action.payload
      }
      
    case 'ADD_CARD_END_PENDING':
      return {
        ...state,
        cardPending: false
      }
      
    case 'ADD_CARD_START_PENDING':
      return {
        ...state,
        cardPending: true
      }
    
    case 'PAYMENT_START_PENDING':
      return {
        ...state,
        paymentPending: true
      }
    case 'PAYMENT_STOP_PENDING':
      return {
        ...state,
        paymentPending: false,
      }
      

		default:
			return state
	}
}