import React from 'react'
import { connect } from 'react-redux';


// Actions
import { login } from '../../../actions/user'
import { use } from '../../../actions/admin'


class Login extends React.Component {

  state = {
    email:"",
    password:""
  }

  handleLogin = () => {

    let { email, password } = this.state 

    this.props.login( email, password )
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.handleLogin ()
    }
  }

  handleInputChange = e => {
    
    this.setState({[e.target.name]: e.target.value })

  }

  componentDidMount() {
    this.props.use()
  }

  render(){

    let { email, password } = this.state
    let { user: { isPending } } = this.props


    return(
      <div id="login">

      
        <div className="box-form">

          <div className="title">
            Login
          </div>


          <input 
            placeholder='Email'
            type="email"
            name="email"
            autoComplete="off"
            maxLength={40}
            value={email}
            onChange={this.handleInputChange}
          />

          <input 
            placeholder='Password'
            type="password"
            name="password"
            maxLength={30}
            autoComplete='new-password'
            value={password}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />

        
      
          <button className="button" onClick={this.handleLogin}>
            { isPending ? <i className="fad fa-spin fa-spinner"></i> : 'Login'}
          </button>

          <hr/>

          <div className="button-change" onClick={()=>this.props.history.push('/register')}>
            Not a customer? Register
          </div>

      </div>
    </div>  
    )
  }

}

export default connect(
  state=>({
    user: state.user
  }),{
    login,
    use
  }
) ( Login )