import React from 'react'

import { 
  Button, 
  FormGroup, 
  Label, 
  Input, 
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput
} from 'reactstrap';

import { connect } from 'react-redux'



class MyInput extends React.Component {

  state = {
    isNotEditable: true,
    newValue:undefined,
  }

  handleOnSave = () => {

    let { newValue } = this.state

    if ( newValue !== undefined )
      this.props.callback( this.state.newValue )
    else
      this.setState({ isNotEditable: !this.props.isNotEditable }) 

  }

  handleOnEdit = () => {

    let { isNotEditable } = this.state

    this.setState({ isNotEditable: !isNotEditable })

  }

  handleChange = e => {

    let { value } = e.target
    let { multiple  } = this.props
    let { newValue } = this.state

    if( multiple ) {
      
      let old  = newValue !== undefined ? newValue : this.props.value.slice(0) 
      
      if( old.includes( value ) )
        old.splice( old.indexOf( value ), 1);  
      else
        old.push( value )

      value = old

    }
  
  

    this.setState({
      newValue: value,
    })
    
  }

  componentDidUpdate(prevProps) {
   
    if ( this.props.value !== prevProps.value ) 
      this.setState({ 
        isNotEditable: !this.props.isNotEditable,
        newValue: undefined
      })
  
  }


  render() {

      
    let { isNotEditable, newValue } = this.state
    let { label, value, type = 'text', prefix, multiple, list, isAdmin, sample } = this.props
    
    let disabled = isNotEditable && !sample ? true : false 
    let display =   isAdmin && !sample ? true : false 

    let listToRender = disabled ? newValue ? newValue : value  : list
    
    

    if ( multiple )
      return(
        <FormGroup>
          <Label>  { label } </Label>
          
          <InputGroup>
            <FormGroup className="multipleCheckBox">
            { listToRender.map( ( item, index ) => {
                 return <CustomInput
                  id={ item }
                  key={ index }
                  value={ item } 
                  type="checkbox"
                  label={ item }  
                  checked={ newValue !== undefined ? newValue.includes( item ) : value.includes( item ) }
                  disabled={ false }
                  onChange={ e => this.handleChange( e ) }
                />
            })

            }
             
              
            </FormGroup>
            
            { display &&
              <InputGroupAddon addonType="append">

                { !isNotEditable ?
                  <React.Fragment>
                    <Button 
                      outline 
                      color="success" 
                      onClick={ this.handleOnSave } 
                    > 
                      Save 
                    </Button> 

                    <Button 
                      outline 
                      color="danger"
                      onClick={ ()=> this.setState({ isNotEditable: !isNotEditable, newValue: undefined })} 
                    > 
                      Cancel 
                    </Button>
                  </React.Fragment>  
                :
                  <Button 
                    outline 
                    color="info" 
                    onClick={ this.handleOnEdit } 
                  > 
                      Edit 
                  </Button>  
                }
              </InputGroupAddon> 
            }   

          </InputGroup>

        </FormGroup>
      )

    return(
      <FormGroup>
      <Label>  { label } </Label>
      <InputGroup>

        { prefix &&  
          <InputGroupAddon addonType="prepend"> 
            <InputGroupText> { prefix } </InputGroupText>
          </InputGroupAddon> 
        }

        <Input
          type={ type  }
          multiple = { multiple } 
          value={ type === 'password' ? isNotEditable ? 'password' : newValue || '' : newValue !== undefined ? newValue : value || '' }
          readOnly={ isNotEditable }
          disabled={ isNotEditable }
          onChange={ e => this.handleChange( e ) }

        >
          { this.props.children } 
        </Input>

        { isAdmin &&
 
          <InputGroupAddon addonType="append">

            { !isNotEditable ?
              <React.Fragment>
                <Button 
                  outline 
                  color="success" 
                  onClick={ this.handleOnSave } 
                > 
                  Save 
                </Button> 

                <Button 
                  outline 
                  color="danger"
                  onClick={ ()=> this.setState({ isNotEditable: !isNotEditable, newValue: undefined })} 
                > 
                  Cancel 
                </Button>
              </React.Fragment>  
            :
              <Button 
                outline 
                color="info" 
                onClick={ this.handleOnEdit } 
              > 
                  Edit 
              </Button>  
            }
          </InputGroupAddon>
        }  
      </InputGroup>
    </FormGroup>
    )
  }
}

export default connect(
  state=>({
    isAdmin: state.user.isAdmin
  })
) ( MyInput )