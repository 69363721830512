import React from 'react'
import { connect } from 'react-redux';
import { Route } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import StripeCheckout from 'react-stripe-checkout';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { 
  SingleDatePicker
} from 'react-dates';


import { 
  EditorState, 
  convertToRaw, 
  ContentState 
} from 'draft-js';

import { 
  Editor 
} from 'react-draft-wysiwyg';

import { 
  FormGroup, 
  Label, 
  Input, 
  Button,
  InputGroup
} from 'reactstrap';

// Utils
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from '../../../utils/card'

import {
  formatMoney
} from '../../../utils'


import { qualificationList, PUBLIC_KEY, VALIDATOR,SUBSCRIBE_PACKS,LVL_2_DEP, SUB_INTERVAL, FIELDS_GROUP, qualificationListClient } from '../../../constants'

//templetes
import SideMenu from '../../SideMenu'

//elements
import MyInput from './Elements/input'
import Note from './Elements/note'

//actions
import { 
  viewUser,
  uploadUserFile,
  updateUser,
  addNote,
  updateNote,
  payWithCard,
  payWithCash,
  createStripeClient,
  addCardToCostumer,
  pay
} from '../../../actions/admin'


import {
  capitalize
} from '../../../utils'

import {
  fetchProfile,
} from '../../../actions/user'



class ViewUser extends React.Component {

  constructor(props) {
    super(props);
    this.editor = React.createRef();
  }

  state = {
    editorState: EditorState.createEmpty(),
    noteToEdit: null,
    noteStatus: 'info',
    paymentView:'history',
    focus: '',
    payment:{
      card:'',
      type: '',
      token:'',
      method: '',
      amount:'',
      amountDue:'',
      amountPaid: '',
      qualification:'',
      plan:{},
      planIndex:'',
      interval:'',
      iteration:'',
      date:null,
    },
    clientPayment: {
      card:'',
      type:'',
      half: true,
      method:'',
      installments:{},
      qualificationIndex: '',
      amount:0,
    }
   
  }


  onToken = token => {

    let { profiles } = this.props
    let selectedUser = profiles[ profiles.list[ this.props.match.params.username ] ] || {}
 
    
    let data = { }

    if ( selectedUser.stripe_client_id ) {
       
      data = {
        stripeUid: selectedUser.stripe_client_id,
        source: token.id,
        uid: selectedUser.objectId
      }
      this.props.addCardToCostumer( data )

    } else {

      data = {
        name: selectedUser.firstName + ' ' + selectedUser.lastName,
        email: selectedUser.email,
        source: token.id,
        uid: selectedUser.objectId
      }
      
      this.props.createStripeClient( data )

    }
      
  }

  onPaymentPressed = () => {

    let { profiles, user: { isAdmin }  } = this.props
    let selectedUser = profiles[ profiles.list[ this.props.match.params.username ] ] || {}
    let { amount, qualification, card, type, method, plan, interval, iteration, date } = this.state.payment

    if( !isAdmin ) 
      return null

    if ( !type.length )
      return alert('Please select a payment type!')
    
    if ( !method.length )
      return alert('Please select a payment method!')
    
    if ( !qualification.length )
      return alert('Please select a qualification!')

    if ( method === 'card' &&  !card.length )
      return alert('Please select a card!')

    if( type === 'installments' && !plan.id )
      return alert('Please select a plan!')
  
    if ( type !== 'installments' && ( !amount.length || !VALIDATOR.number.exp.test(amount) ) )
      return alert('Please fill amount field!')

    if ( type === 'installments' && plan.id === 'custom' && ( !amount.length || !VALIDATOR.number.exp.test(amount) ) )
      return alert('Please fill amount field!')

    if ( type === 'installments' && plan.id === 'custom' && !interval.length )
      return alert('Please select a interval for subscription!')

    if ( type === 'installments' && plan.id === 'custom' && ( !iteration.length || !VALIDATOR.number.exp.test(iteration) ) )
      return alert('Please fill iteration field!') 
    
    if ( type === 'installments'  && ( !date ) )
      return alert('Please select a start date!')    

    let data = {
      type,
      method,
      amount: type === 'installments' ? plan.id === "custom" ? parseFloat( amount ) : plan.amount  :  parseFloat( amount ),
      description: qualification,
      uid: selectedUser.objectId,
    }
    
    if ( type === 'installments' && plan.id !== 'custom' ) {

      data.iteration = plan.iteration
      data.plan = plan.id
      data.startDate = String( date.unix() )

    } else if( type === 'installments' && plan.id === 'custom' ){

      data.iteration = parseInt(  iteration )
      data.plan = plan.id
      data.interval = interval
      data.startDate = String( date.unix() )
    
    }
      
      
    if ( method === 'card' ) {

      data.customer = selectedUser.stripe_client_id
      data.source = card

      this.props.payWithCard( data, selectedUser )
    
    } else {

      this.props.payWithCash( data, selectedUser )

    }

   

  }

  onPaymentPressedClient = async () => {

    let { user: { objectId }, profiles  } = this.props
    let self = profiles[objectId] || {}
    let { amount, qualification, card, type,  installments } = this.state.clientPayment


    if ( !type.length )
      return alert('Please select a payment type!')
    
    if ( !qualification.length )
      return alert('Please select a qualification!')

    if ( !card.length )
      return alert('Please select a card!')


    let data = {
      type,
      method:'card',
      amount: type === 'installments' ?  50 : type === "half" ? parseFloat( amount ) / 2 :  parseFloat( amount ),
      description: qualification,
      uid: objectId,
      customer:self.stripe_client_id,
      source:card
    }
    
    if ( type === 'installments' ) {

      let date = new Date()
      date.setDate( date.getDate() + 7 )
      let ts = Math.round( date.getTime() / 1000)

      data.iteration = 12
      data.deposit = LVL_2_DEP
      data.plan =  installments.id
      data.startDate = String( ts )

      let deposit ={ ...data }
      deposit.amount = LVL_2_DEP
      deposit.type = 'deposit'

      await this.props.pay( deposit ,self)
    
    }
  
      await this.props.pay( data, self )
     

  }



  handleChange = e => {

    let { name, value } = e.target

    if( name === 'qualification') {

      var options = e.target.options;
      value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
    }

    this.setState({
      [name]: value,
    })
    
  }

  handleChangePayment = e => {

    let { name, value } = e.target

    this.setState({
      payment: {  
        ...this.state.payment, 
        [name]: value
      } ,
    })

  }

  handleChangePaymentClient = e => {

    let { name, value } = e.target


    if ( name === "qualification" )  {

      let obj = qualificationListClient[value]

      return this.setState({
        clientPayment: {  
          ...this.state.clientPayment,
          installments:  obj.installments,
          half: obj.half,
          type:'',
          [name]: obj.name,
          qualificationIndex: value,
          amount: obj.fullPrice
        } ,
      })

    }
      

    this.setState({
      clientPayment: {  
        ...this.state.clientPayment, 
        [name]: value
      } ,
    })

  }


  onSaveUser = ( name, val ) => {

    let { profiles  } = this.props
   
    let selectedUser = profiles[ profiles.list[ this.props.match.params.username ] ] || {}

    if ( name === 'isAdmin' )
      val = val === 'true' ? true : false

     this.props.updateUser( selectedUser , name, val )

  }


  onCleanEditor = () => {

    this.setState({ 
      noteToEdit: null,
      editorState: EditorState.createEmpty(),
      noteStatus:'info' 
    })

  }

  
  onSaveNote = () => {

    let { profiles  } = this.props
    let selectedUser = profiles[ profiles.list[ this.props.match.params.username ] ] || {}
    let currentUser = this.props.user

    if( !currentUser.isAdmin ) 
      return null
  
      let note = {
        authorId: currentUser.objectId ,
        message: this.state.editorSourceHTML,
        status:  this.state.noteStatus
      }
    
      this.props.addNote( selectedUser, note )

    this.onCleanEditor()

  }


  onUpdateNote = () => {

    let { profiles, user  } = this.props
    let selectedUser = profiles[ profiles.list[ this.props.match.params.username ] ] || {}
    let currentUser = this.props.user

    let { noteToEdit, editorSourceHTML, noteStatus } = this.state 

    if( !currentUser.isAdmin ) 
      return null

    if( noteToEdit.authorId === user.objectId )
      noteToEdit.message = editorSourceHTML

    noteToEdit.status = noteStatus
 

    this.props.updateNote( selectedUser, noteToEdit )
    
    this.onCleanEditor()

  }

  onNoteEdit = note => {

    let value = note.message;

    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    let editorState = EditorState.createWithContent(contentState);

  
    this.setState({
      editorState,
      noteToEdit: note,
      noteStatus: note.status || 'info' 
    })
    
   this.editor.current.focusEditor()   

  }
  

  handleUplaodFiles = userId =>  {

    this.props.uploadUserFile( userId, this.state.category, this.file.files )

    this.setState({
      category: '',
    
    })
    
    this.file = null

  }

  handleInputFocus = (e) => {
    this.setState({  focus: e.target.name });
  }
  
  handleInputChange = (e) => {
    let { name, value } = e.target;

    if ( name  === 'number' ) 
        value = formatCreditCardNumber( value )

      if ( name === 'expiry' ) 
        value = formatExpirationDate( value )
      
      if ( name === 'cvc' )
        value = formatCVC( value )
    
    this.setState({ 
      card:{ 
        ...this.state.card,
        [name]: value 
      }
    });
  }


  renderFile = file => {

    return <a key={ file.fileName } href={ file.url } > { file.fileName  } </a>  

  }

  renderFileCategory = category => {
    
    let categoryName = category[0] === 'application' ? 'Application Form' : 
    category[0] === 'documents' ? 'Site Documents' : 
    category[0] === 'photos' ? 'Site Photos' :
    category[0] === 'videos' ? 'Site Videos' :
    category[0] === 'diploma' ? 'Diploma' :
    null

    let files = Object.values(category[1])
    
    if ( categoryName === null )
      return

    return(
      <div className="section files" key={ categoryName }>
         <span className="subTitle"> { categoryName } </span>
         <span className="info "> 
          { files.map( file => {
              return this.renderFile( file )
            })
          }
         </span>

      </div>
    ) 
      
  }

  onEditorStateChange = editorState => {

    let content =  editorState.getCurrentContent()
    
    let editorSourceHTML = draftToHtml(convertToRaw(content))
    

    this.setState({
      editorState,
      editorSourceHTML
    });

  }


  componentDidMount() {

    let { profiles, user:{ isAdmin, objectId } } = this.props
    let self = profiles[objectId]
    
    if ( !isAdmin && self.username !==  this.props.match.params.username  )
        this.props.history.push('/')

    this.props.fetchProfile( this.props.match.params.username  )
  }

  
  render(){
    
    
    let { noteToEdit, noteStatus, payment, paymentView, clientPayment } = this.state
    let { profiles, user:{ isAdmin, objectId }, utils:{ paymentPending, cardPending } } = this.props
    let selectedUser = profiles[ profiles.list[ this.props.match.params.username ] ] || {}
    let paymentsHistory = selectedUser.paymentsHistory|| []
    let self = profiles[objectId]
    let admin = profiles[ selectedUser.createdBy ]
    let date = selectedUser.createdAt && selectedUser.createdAt.toDate()
    let cards = selectedUser.cards || []

    return(
      <div className="dashboard">

        { cardPending && 
        <div className="loading-box">
          <div className="spinner-box">
            <i className="fad fa-spin fa-spinner"></i>
          </div>
        </div>
        }

        <Route component={SideMenu} />

        <div className="detail">
     

      <div id ="viewUser" >
        { !selectedUser.objectId ?
            <div className="spinnerBox">
            <i className="fas fa-spinner fa-spin" />
          </div>
        :
          <div className="content-box">
            
          <div className="header">

            <div className="title">
              <i className="fas fa-user"/>
              <span> Personal Details </span>
            </div>
               <span> Register date:  { `${ date.getDate() }/${ date.getMonth() + 1 }/${ date.getFullYear() }`  } </span>
          </div>

         
          <div>
         

            <MyInput
              label = 'First Name'
              value = { selectedUser.firstName }
              callback = { val => this.onSaveUser( 'firstName', val )}
            />

            <MyInput
              label = 'Middle Name'
              value = { selectedUser.middleName }
              callback = { val => this.onSaveUser( 'middleName', val )}
            />

            <MyInput
              label = 'Last Name'
              value = { selectedUser.lastName }
              callback = { val => this.onSaveUser( 'lastName', val )}
            />

            <MyInput
              label = 'Date Of Birth'
              value = { selectedUser.dateOfBirth }
              callback = { val => this.onSaveUser( 'dateOfBirth', val )}
            />

            <MyInput
              label = 'Email'
              value = { selectedUser.email }
              callback = { val => this.onSaveUser( 'email', val )}
            />

            <MyInput
              label = 'Password'
              value = { selectedUser.password }
              type='password'
              callback = { val => this.onSaveUser( 'password', val )}
            />

            <MyInput
              label = 'Phone Number'
              value = { selectedUser.phoneNumber }
              callback = { val => this.onSaveUser( 'phoneNumber', val )}
            />
            
            <MyInput
              label = 'Address'
              value = { selectedUser.address }
              callback = { val => this.onSaveUser( 'address', val )}
            />

            <MyInput
              label = 'House Number'
              value = { selectedUser.houseNumber }
              callback = { val => this.onSaveUser( 'houseNumber', val )}
            />

            <MyInput
              label = 'Post Code'
              value = { selectedUser.postCode }
              callback = { val => this.onSaveUser( 'postCode', val )}
            />

            { isAdmin && 
              <MyInput
                label = 'Is Admin'
                type='select'
                value = { selectedUser.isAdmin === true ? true : false }
                callback = { val => this.onSaveUser( 'isAdmin', val )}
              >
                <option value={false}>No</option>
                <option value={true} >Yes</option>
  
              </MyInput>
            }
            
            
          </div>

        </div>
        }
      </div>



      <div id ="viewUser" >
        { !selectedUser.objectId ?
            <div className="spinnerBox">
            <i className="fas fa-spinner fa-spin" />
          </div>
        :
          <div className="content-box">
            
          <div className="header">

            <div className="title">
              <i className="fas fa-user"/>
              <span> Additional Info </span>
            </div>
              
          </div>

         
          <div>
            { self.isAdmin &&
            <MyInput
                label = 'Status'
                type='select'
                value = { selectedUser.status }
                callback = { val => this.onSaveUser( 'status', val )}
              >
                <option value="" > Select status </option>
                <option value="missing" >Missing</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>

              </MyInput>
            }

            <MyInput
              label = 'Intermediar'
              value = { selectedUser.intermediar }
              callback = { val => this.onSaveUser( 'intermediar', val )}
            />

            <MyInput
              label = 'NINO'
              value = { selectedUser.nino }
              callback = { val => this.onSaveUser( 'nono', val )}
            />

            <MyInput
              label = 'CSCS'
              value = { selectedUser.cscs }
              callback = { val => this.onSaveUser( 'cscs', val )}
            />


            <MyInput
              label = 'Qualification'
              type='select'
              value = { selectedUser.qualification }
              callback = { val => this.onSaveUser( 'qualification', val )}
              multiple
              list={ qualificationList }
            />

            { self.isAdmin &&
            <div className="media-status-group">
              <div className="media-group">
                <span> PHOTOS </span>
                <MyInput
                      type='select'
                      value = { !selectedUser.hasOwnProperty('files') ? '' : !selectedUser.files.hasOwnProperty('photos') ? '' : selectedUser.files.photos.status }
                      callback = { val => this.onSaveUser( 'files.photos.status', val )}
                    >
                      <option value="" > Select status </option>
                      <option value="missing" >Missing</option>
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>

                    </MyInput>
              </div>

              <div className="media-group">
                <span> VIDEOS </span>
                <MyInput
                      type='select'
                      value = { !selectedUser.hasOwnProperty('files') ? '' : !selectedUser.files.hasOwnProperty('videos') ? '' : selectedUser.files.videos.status }
                      callback = { val => this.onSaveUser( 'files.videos.status', val )}
                    >
                      <option value="" > Select status </option>
                      <option value="missing" >Missing</option>
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>

                    </MyInput>
              </div>

              <div className="media-group">
                <span> DOCUMENTS </span>
                <MyInput
                      type='select'
                      value = { !selectedUser.hasOwnProperty('files') ? '' : !selectedUser.files.hasOwnProperty('documents') ? '' :  selectedUser.files.documents.status }
                      callback = { val => this.onSaveUser( 'files.documents.status', val )}
                    >
                      <option value="" > Select status </option>
                      <option value="missing" >Missing</option>
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                      <option value="weProvide">We Provide</option>

                    </MyInput>
              </div>

              <div className="media-group">
                <span> APPLICATION </span>
                <MyInput
                      type='select'
                      value = { !selectedUser.hasOwnProperty('files') ? '' : !selectedUser.files.hasOwnProperty('application') ? '' : selectedUser.files.application.status }
                      callback = { val => this.onSaveUser( 'files.application.status', val )}
                    >
                      <option value="" > Select status </option>
                      <option value="missing" >Missing</option>
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>

                    </MyInput>
              </div>
            </div>
            }   

            { self.isAdmin &&
            <FormGroup>
              <Label>  CreatedBy </Label>
          
              <Input
                value={ admin ? `${ admin.firstName } ${admin.middleName} ${admin.lastName}`: 'Loading ...' }
                readOnly
                disabled
              />
            </FormGroup>
            }
            
          </div>

        </div>
        }
      </div>

      <div id ="viewUser" >
        { !selectedUser.objectId ?
            <div className="spinnerBox">
            <i className="fas fa-spinner fa-spin" />
          </div>
        :
          <div className="content-box">
            
          <div className="header">

            <div className="title">
              <i className="fas fa-user"/>
              <span> Payments </span>
            </div>
              
          </div>
         
          <div className="paymentsStats">

            <div>
              Total: <span>&#163;</span> { formatMoney( selectedUser.fullPayment || 0 ) }
            </div>

            <div>
              Paid: <span>&#163;</span> { formatMoney( selectedUser.amountPaid || 0 ) }
            </div>


            <div>
              Due: <span>&#163;</span> { formatMoney( selectedUser.amountDue || 0 ) }
            </div>
            
            
          </div>

        </div>
        }
      </div>



      <div id ="viewUser" >
        { !selectedUser.objectId ?
            <div className="spinnerBox">
            <i className="fas fa-spinner fa-spin" />
          </div>
        :
          <div className="content-box">
            
          <div className="header">

            <div className="title">
              <i className="fas fa-user"/>
              <span> Payment Details </span>
            </div>
            <div className="payment-btn-group">
              <span onClick={ ()=> this.setState({ paymentView: 'history'}) } className={ paymentView === 'history' ? 'active' : '' }> History </span>
              <span onClick={ ()=> this.setState({ paymentView: 'payment'}) } className={ paymentView === 'payment' ? 'active' : '' }> { self.isAdmin ? 'New Payment' : 'My Cards' } </span> 
              <StripeCheckout
              name='Add Card To Costumer'
              panelLabel='Add Card'
              stripeKey={ PUBLIC_KEY }
              token={this.onToken}
            >
              Add Card
            </StripeCheckout>
            </div>
           
          </div>

         
          <div>

          { paymentView === 'history' &&
            <React.Fragment>
             { paymentsHistory.length ?
              <div className="payment-history-table">
                <div className="payment-history-table-header">
                  <div className='payment-history-table-cell'> 
                    Status
                  </div>
                  <div className='payment-history-table-cell'> 
                    Amount
                  </div>
                  <div className='payment-history-table-cell'> 
                    Method
                  </div>
                  <div className='payment-history-table-cell'> 
                    Type
                  </div>
                  <div className='payment-history-table-cell bigCell'> 
                    Description
                  </div>
                  <div className='payment-history-table-cell'> 
                    Date
                  </div>
                </div>

              { paymentsHistory.slice(0).reverse().map( ( payment, index )=> {

                let date = new Date( payment.createdAt )
                let day =  date.getDate() < 10 ? "0"+date.getDate() : date.getDate()
                let month = ( date.getMonth() + 1 ) < 10 ? "0"+ ( date.getMonth() + 1 ) : date.getMonth() + 1
                let year = date.getFullYear()
                let hour =  date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
                let min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()

                return <div key={index} className={`payment-history-table-row ${ index % 2 === 0 ? 'odd' : ''}`}>
                <div className='payment-history-table-cell'> 
                { capitalize( payment.status ) }
                </div>
                <div className='payment-history-table-cell'> 
                <span> &#163; </span> { payment.amount } 
                </div>
                <div className='payment-history-table-cell'> 
                  { capitalize( payment.method ) }
                </div>
                <div className='payment-history-table-cell'> 
                  { capitalize( payment.type ) }
                </div>
                <div className='payment-history-table-cell bigCell'> 
                  <span>{ payment.description } </span>
                </div>
                <div className='payment-history-table-cell'> 
                  { `${day}/${month}/${year} ${hour}:${min}` }
                </div>
              </div>
              })}
              
            </div>
            :
            <div className="payment-history-message">
              NO PAYMENT HISTORY
            </div>
            
            }
            </React.Fragment>
          }  


          { paymentView === 'payment' &&
            <React.Fragment>

            { !self.isAdmin && 
              <React.Fragment >
                {/* <FormGroup>

                  <Label> Qualification</Label>

                  <Input type="select" name="qualification" id="exampleSelect" disabled={ !self.isAdmin }  value={ clientPayment.qualificationIndex }  onChange={ this.handleChangePaymentClient }>
                    <option value="" > Select a qualification </option>
                    {  qualificationListClient.map( ( item, index ) => { return <option value={ index }  key={ index }> { item.name } </option>  })}
                  </Input>

                </FormGroup>

                <FormGroup>
                  <Label>  Payment type </Label>
              
                  <Input type="select" name="type" id="exampleSelect" value={ clientPayment.type }  onChange={ this.handleChangePaymentClient }>
                    <option value="" > Select a payment type </option>
                    <option value="full">Full</option>
                    { clientPayment.half && <option value="half">Half</option> }
                    { clientPayment.installments.id && <option value="installments" >Installments</option> }
                   
                  </Input>

              </FormGroup> */}
              
              { cards.length ?
                <FormGroup>

                  <Label> Cards </Label>

                  <Input type="select" name="card" id="exampleSelect"  value={ clientPayment.card }   onChange={ this.handleChangePaymentClient }>
                    <option value="" > Select a card </option>
                    { cards.map( ( card , index ) => {
                      return <option value={ card.id } key={ index }> { '**** ***** ***** ' + card.last4 + ' - ' +  card.brand } </option>
                    })}
                  </Input>

                </FormGroup>
                :
                <StripeCheckout
                  name='Add Card To Costumer'
                  panelLabel='Add Card'
                  stripeKey={ PUBLIC_KEY }
                  token={this.onToken}
                  className="button"
                >
                  Add Card
                </StripeCheckout> 
              }
              
              {/* { clientPayment.qualificationIndex  && clientPayment.type &&
              <FormGroup>
                  <Label> Price </Label>  
                  <Input 
                    value={ clientPayment.type === 'installments' ? LVL_2_SUB_NAME :  clientPayment.type === 'half' ? ( clientPayment.amount / 2 ) : clientPayment.amount   }
                    name="amount"
                    disabled={true}
                    onChange={ this.handleChangePayment }
                  />
                </FormGroup>
              } */}

                </React.Fragment>
            }
            

            { self.isAdmin &&
            <React.Fragment>
            
              <FormGroup>
                <Label>  Payment type </Label>
            
                <Input type="select" name="type" id="exampleSelect" value={ payment.type }  onChange={ this.handleChangePayment }>
                  <option value="" > Select a payment type </option>
                  <option value="full">Full</option>
                  <option value="old">Old Payment</option> 
                  <option value="installments" >Installments</option>
                  <option value="registration" >Registration</option> 
                 <option value="custom" > Custom </option> 
                </Input>

              </FormGroup>

              <FormGroup>

                <Label> Payment Method </Label>

                <Input type="select" name="method" id="exampleSelect"  value={ payment.method }  onChange={ this.handleChangePayment }>
                  <option value="" > Select a payment method </option>
                  <option value="cash" > Cash </option> 
                  <option value="card"> Card </option>
                 <option value="transfer"> Transfer </option> 
                 <option value="pos"> POS </option> 
                </Input>

              </FormGroup>

              { payment.method === 'card' &&  
                <FormGroup>

                  <Label> Card </Label>

                  <Input type="select" name="card" id="exampleSelect"  value={ payment.card }   onChange={ this.handleChangePayment }>
                    <option value="" > Select a card </option>
                    { cards.map( ( card , index ) => {
                      return <option value={ card.id } key={ index }> { '**** ***** ***** ' + card.last4 + ' - ' +  card.brand } </option>
                    })}
                  </Input>

                </FormGroup>
              }

           <FormGroup>

                <Label> Qualification</Label>

                <Input type="select" name="qualification" id="exampleSelect"  value={ payment.qualification }  onChange={ this.handleChangePayment }>
                  <option value="" > Select a qualification </option>
                  {  qualificationList.map( item => { return <option value={ item }  key={ item }> { item } </option>  })}
                </Input>

              </FormGroup>
              


              

              { payment.type === 'installments' &&

                <FormGroup>

                  <Label> Plan </Label>

                  <DropDownListComponent 
                    id="plan" 
                    popupHeight='200px' 
                    fields={FIELDS_GROUP} 
                    dataSource={SUBSCRIBE_PACKS}
                    placeholder="Select a plan" 
                    name='plan'
                    change={  e => this.setState({ payment: { ...payment, plan: e.itemData } }) }
                     />

                  {/* <Input type="select" name="plan" id="exampleSelect"  value={ payment.planIndex }   onChange={ this.handleChangePayment }>
                    <option value="" > Select a plan </option>
                    <div> bla </div>
                    { SUBSCRIBE_PACKS.map( ( plan , index ) => {
                      return <option value={ index } key={ index }> { plan.name } </option>
                    })}
                  </Input> */}

                </FormGroup>

              }
              { ( payment.type === 'installments' ) &&
                
          
                <FormGroup>
                  <Label> Date </Label> 
                  <InputGroup> 
                  <SingleDatePicker
                    
                    date={ payment.date } // momentPropTypes.momentObj or null
                    onDateChange={date => this.setState({ payment:{ ...payment, date } })} // PropTypes.func.isRequired
                    isOutsideRange={() => false}
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    displayFormat="DD/MM/YYYY"
                    id="date_piker_create_user" // PropTypes.string.isRequired,
                  />
                  </InputGroup>
              </FormGroup>
              }

              { ( ( payment.type !== 'installments' && payment.type !== 'partial' ) || ( payment.type === 'installments' && payment.plan.id === 'custom' ) ) &&
                <FormGroup>
                  <Label> Amount </Label>  
                  <Input 
                    value={ payment.amount }
                    name="amount"
                    onChange={ this.handleChangePayment }
                  />
                </FormGroup>

              }  

              { ( payment.type === 'installments' && payment.plan.id === 'custom' ) &&
                <FormGroup>
                  <Label> Subscription Interval </Label>  

                  <Input type="select" name="interval" id="exampleSelect"  value={ payment.interval }   onChange={ this.handleChangePayment }>
                    <option value="" > Select a interval </option>
                    { SUB_INTERVAL.map( ( interval , index ) => {
                      return <option value={ interval } key={ index }> { capitalize( interval ) } </option>
                    })}
                  </Input>

                </FormGroup>

              } 

              { ( payment.type === 'installments' && payment.plan.id === 'custom' ) &&
                <FormGroup>
                  <Label> Number of iteration </Label>  
                  <Input 
                    value={ payment.iteration }
                    name="iteration"
                    onChange={ this.handleChangePayment }
                  />
                </FormGroup>

              } 

              </React.Fragment> 

          }

            
              { self.isAdmin &&
              <button disabled={ paymentPending } className='payment-btn' onClick={ () => self.isAdmin ? this.onPaymentPressed() : this.onPaymentPressedClient() }>
                { paymentPending ?  <i className="fas fa-spinner fa-spin" />  : "PAY NOW" }
              </button>
              }

            </React.Fragment>

          }


          </div>

        </div>
        }
      </div>


        
      { self.isAdmin &&

      <div id ="viewUser" >
        { !selectedUser.objectId ?
            <div className="spinnerBox">
            <i className="fas fa-spinner fa-spin" />
          </div>
        :
          <div className="content-box">
            
          <div className="header">

            <div className="title">
              <i className="fas fa-user"/>
              <span> Comments </span>
            </div>
              
          </div>

         
          <div>

           

            <div className='notes-wrapper'>
              <div className='notes-container'>
                {  Object.values( selectedUser.notes || {} ).map( note  => {
                    return <Note
                      //canEdit={ note.authorId === objectId } 
                      key={ note.createdAt }
                      note={ note } 
                      onEdit={ data => this.onNoteEdit( data ) }
                      />
                  }) 
                }
                

              </div>
               { self.isAdmin &&   
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperEditor"
                  editorClassName="editorTextArea"
                  readOnly={ noteToEdit !== null && noteToEdit.authorId !== objectId  }
                  ref={ this.editor }
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji',  'remove', 'history'],
                  }}
                />
               } 


               { self.isAdmin && 
              <div className='editorButtons'>

                 <Input
                  type='select'
                  value={ noteStatus }
                  name='noteStatus'
                  onChange={ e => this.handleChange( e ) }
                >
                  
                  <option value="info" >Info</option>
                  <option value="important" >Important</option>
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>

                </Input>

                { noteToEdit !== null &&
                  <Button
                    outline 
                    color="danger"
                    className='note-cancel'
                    onClick = {  () =>   this.onCleanEditor() } 
                  >
                    CANCEL
                  </Button>
                }

                <Button
                  outline 
                  color="success"
                  className='note-save'
                  onClick = {  () =>  noteToEdit === null ? this.onSaveNote() : this.onUpdateNote() } 
                >
                  { noteToEdit === null ? 'CREATE' : 'UPDATE' }
                </Button>

              </div>
               }
              

            </div>  
            
            
          </div>

        </div>
        }
      </div> 
      }





      </div>
     </div> 
      
    )
  }
}

export default connect(
  state=>({
    profiles: state.profiles,
    utils: state.utils,
    user: state.user,
  }),{
    viewUser,
    uploadUserFile,
    fetchProfile,
    updateUser,
    addNote,
    updateNote,
    payWithCard,
    payWithCash,
    createStripeClient,
    addCardToCostumer,
    pay
  }
) ( ViewUser )