export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBonNYsScRggsFxjFU7WBoxJRzQf7lbkzM",
  authDomain: "qmb-db.firebaseapp.com",
  databaseURL: "https://qmb-db.firebaseio.com",
  projectId: "qmb-db",
  storageBucket: "qmb-db.appspot.com",
  messagingSenderId: "715706418593"
}




// // // qmb -copy 
// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyAJVTigc3WSj5UGjMsZYEOlPgeF3IUmWOY",
// 	authDomain: "qmb-copy.firebaseapp.com",
// 	databaseURL: "https://qmb-copy.firebaseio.com",
// 	projectId: "qmb-copy",
// 	storageBucket: "qmb-copy.appspot.com",
// 	messagingSenderId: "1026847473154",
// 	appId: "1:1026847473154:web:c4ab806ba664dfdc"
// }




// LIVE
export const PUBLIC_KEY = 'pk_live_mKMiPqbyfWXzQggWkiH0Wkv200Z0qeJwvH'
// DEMO
//export const PUBLIC_KEY = 'pk_test_eF7T6vPrMIwHPJnvKBBpLS2n00LBBCNXuD'

// LINKS 

// LIVE
export const  SIMPLE_CHARGE = 'https://us-central1-qmb-db.cloudfunctions.net/chargeStripe'
export const SUBSCRIPTION = 'https://us-central1-qmb-db.cloudfunctions.net/subscribeClient'
export const CUSTOM_SUBSCRIPTION = 'https://us-central1-qmb-db.cloudfunctions.net/createPlan'
export const STRIPE_CREATE_CLIENT = 'https://us-central1-qmb-db.cloudfunctions.net/createClientStripe'
export const STRIPE_ADD_CARD = 'https://us-central1-qmb-db.cloudfunctions.net/addCardToCostumer'
//DEMO
// export const  SIMPLE_CHARGE = 'https://us-central1-qmb-copy.cloudfunctions.net/chargeStripe'
// export const SUBSCRIPTION = 'https://us-central1-qmb-copy.cloudfunctions.net/subscribeClient'
// export const CUSTOM_SUBSCRIPTION = 'https://us-central1-qmb-copy.cloudfunctions.net/createPlan'
// export const STRIPE_CREATE_CLIENT = 'https://us-central1-qmb-copy.cloudfunctions.net/createClientStripe'
// export const STRIPE_ADD_CARD = 'https://us-central1-qmb-copy.cloudfunctions.net/addCardToCostumer'






// LIVE
export const SUBSCRIBE_PACKS = [
	{
		id:'custom',
		plan: 'Custom Plan', 
		category: 'Custom', 
	},
	{ 
		id: 'plan_GebpIVz8MkDtu1',
		plan: '2 x £300 / month ', 
		category: 'NVQ L2',
		amount:300,
		iteration: 2, 
	},
	{ 
		id: 'plan_GebpyGZtMOlrKQ',
		plan: '2 x £675/ month', 
		category: 'NVQ L2',
		amount:675,
		iteration: 2,
	},
	{ 
		id: 'plan_Gebn8jg7XkuYbS',
		plan: '12 x £50  / week', 
		category: 'NVQ L2',
		amount:50,
		iteration: 12,
	},
	{ 
		id: 'plan_Gebs8bMUcegKw4',
		plan: '2 x £550  / month', 
		category: 'NVQ L3',
		amount:550,
		iteration: 2,
	},
	{ 
		id: 'plan_GebsUakPwvtEsI',
		plan: '12 x £84  / week', 
		category: 'NVQ L3',
		amount:84,
		iteration: 12,
	},
	{ 
		id: 'plan_GebulHAn1aZqQ4',
		plan: '2 x £700  / month', 
		category: 'NVQ L4',
		amount:700,
		iteration: 2,
	},
	{ 
		id: 'plan_Gebty5K8eFuKw3',
		plan: '14 x £75  / week', 
		category: 'NVQ L4',
		amount: 75,
		iteration: 14,
	},
	{ 
		id: 'plan_Gebvb0aeNuY65F',
		plan: '5 x £250  / month', 
		category: 'NVQ L6',
		amount:250,
		iteration: 5,
	},
	{ 
		id: 'plan_GebwQsDoULCj51',
		plan: '2 x £1000  / month', 
		category: 'NVQ L7',
		amount:1000,
		iteration: 2,
	},
	
]

// LIVE
export const LVL_2_SUB = 'plan_Gebn8jg7XkuYbS'
export const LVL_2_DEP = 120
export const LVL_2_SUB_NAME = '£120 + £50/12weeks'
//DEMO
//export const LVL_2_SUB = 'plan_Gecvqt4mK1es0k'


//DEMO
// export const SUBSCRIBE_PACKS = [
// 	{
// 		id:'custom',
// 		plan: 'Custom Plan', 
// 		category: 'Custom', 
// 	},
// 	{ 
// 		id: 'plan_GecwOxPQZi9O38',
// 		plan: '2 x £300 / month ', 
// 		category: 'NVQ L2',
// 		amount: 300,
// 		iteration: 2, 
// 	},
// 	{ 
// 		id: 'plan_Gecwpa7MESnVbA',
// 		plan: '2 x £675/ month', 
// 		category: 'NVQ L2',
// 		amount: 675,
// 		iteration: 2,
// 	},
// 	{ 
// 		id: 'plan_Gecvqt4mK1es0k',
// 		plan: '12 x £50  / week', 
// 		category: 'NVQ L2',
// 		amount: 50,
// 		iteration: 12,
// 	},
// 	{ 
// 		id: 'plan_GectnyODm8ZzWR',
// 		plan: '2 x £550  / month', 
// 		category: 'NVQ L3',
// 		amount: 550,
// 		iteration: 2,
// 	},
// 	{ 
// 		id: 'plan_GecuIIlFJ23YRs',
// 		plan: '12 x £84  / week', 
// 		category: 'NVQ L3',
// 		amount: 84,
// 		iteration: 12,
// 	},
// 	{ 
// 		id: 'plan_Gecx3DSl2wN5FL',
// 		plan: '2 x £700  / month', 
// 		category: 'NVQ L4',
// 		amount: 700,
// 		iteration: 2,
// 	},
// 	{ 
// 		id: 'plan_GecxdVh5b96wSQ',
// 		plan: '14 x £75  / week', 
// 		category: 'NVQ L4',
// 		amount: 75,
// 		iteration: 14,
// 	},
// 	{ 
// 		id: 'plan_GeczEmqksX4jPO',
// 		plan: '5 x £250  / month', 
// 		category: 'NVQ L6',
// 		amount: 250,
// 		iteration: 5,
// 	},
// 	{ 
// 		id: 'plan_Ged0TQAueRHgpP',
// 		plan: '2 x £1000  / month', 
// 		category: 'NVQ L7',
// 		amount: 1000,
// 		iteration: 2,
// 	},
	
// ]

export const FIELDS_GROUP = { groupBy: 'category', text: 'plan', value: 'id' };


export const SUB_INTERVAL = [ 'day', 'week', 'month', 'year' ]


export const VALIDATOR = {
	name: {
		length: [ 2, 30 ],
		exp: 		RegExp(/^(?=.{2,30}$)[a-zA-Z\s]+$/)
	},
	email: {
		length: [ 5, 30 ],
		exp: 		RegExp(/^(?=.{5,30}$)[\w.-]+@[\w.-]+\.\w{2,4}$/)
	},
	username: {
		length: [ 4, 20 ],
		exp: 		RegExp(/^(?=.{4,20}$)[a-z0-9][a-z0-9_]+[a-z0-9]$/)
	},
	password: {
		length: [ 6, 30 ],
		exp: 		RegExp(/^.{6,30}$/)
	},
	number: {
		value: 	[ 1, 99 ],
		exp: 		RegExp(/^\$?\d+(\.\d+)?$/)
	},
	phoneNumber: {
		exp: RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/)
	},
	nino: {
		length: [ 9 ],
		exp: RegExp(/^(?=.{9}$)[a-zA-Z0-9]+$/i)
	},
	cscs: {
		length: [ 8 ],
		exp: RegExp(/^(?=.{8}$)[0-9]+$/i)
	},
	price: {
		exp: RegExp(/^\d{0,8}(\.\d{1,4})?$/)
	},
	dob: {
		exp: RegExp(/(0[1-9]|[12][0-9]|3[01])[ /.](0[1-9]|1[012])[ /.](19|20)\d\d/)
	}
}

export const qualificationList = [
	'GQA',
	'Tax Return',
	'18th edition',
	'IPAF 3a 3b',
	'ECS',
	'AM2',
	'CCDO',
	'PTS',
	'Lucas',
	'Pasma',
	'Harness',
	'Fire Marshal',
	'Level 1 Award in Health and Safety in Construction',
	'IOSH Working Safely Training Course',
	'IOSH Managing Safely Training Course',
	'L3 Award in First Aid at Work',
	'CITB Site Manager Safety Training Scheme (SMSTS)',
	'CITB Site Supervisors Safety Training Scheme (SSSTS)',
	'Slinger / Signaller (A40)',
	'Forward Tipping Dumper (A09)',
	'Ride on Road Roller (A31)',
	'Excavator 360º above 10 tonnes (A59)',
	'Lift Supervisor (A62)',
	'Plant & vehicle marshaller (A73)',
	'Telescopic Forklift (Telehandler) (A17)',
	'Traffic Marshal',
	'NVQ Level 2 Pipewor',
	'NVQ Level 2 Thermal Insualtion',
	'NVQ L2 Bricklaying',
	'NVQ L2 Demolition',
	'NVQ L2 Dry Lining Finishing',
	'NVQ L2 Dry Lining Fixing',
	'NVQ L2 Fitted Interiors',
	'NVQ L2 Floor Screeding',
	'NVQ L2 Floorcovering',
	'NVQ L2 Formwork',
	'NVQ L2 Groundworking',
	'NVQ L2 Hygienic Cladding',
	'NVQ L2 Industrial Painting Decorating',
	'NVQ L2 Painting Decorating',
	'NVQ L2 Passive Fire Protection',
	'NVQ L2 Plastering',
	'NVQ L2 Carpenter - Shopfitting',
	'NVQ L2 Site Carpentry - Wood Ocupation',
	'NVQ L2 Wall & Floor Tiling',
	'NVQ L2 Cladding',
	'NVQ L2 Duckwork',
	'NVQ L2 Multitrader',
	'NVQ L2 Plumbing',
	'NVQ L2 Roofing',
	'NVQ L2 Steel erector',
	'NVQ L2 Steel fixing',
	'NVQ L2 Welding',
	'NVQ L2 Plumber - RO',
	'NVQ L2 Plumber - UK',
	'NVQ L2 Streetworks Operative',
	'NVQ L3 Bricklaying',
	'NVQ L3 Formwork',
	'NVQ L3 Occupational Supervision',
	'NVQ L3 Painting Decorating',
	'NVQ L3 Plastering',
	'NVQ L3 Wall & Floor Tiling',
	'NVQ L3 Wood Occupations',
	'NVQ L3 Construction Contracting Operation',
	'NVQ L3 Electric',
	'NVQ L4 Construction Site Supervision',
	'NVQ L5 Health and Safety Practice',
	'NVQ L6 Contracting Operations',
	'NVQ L6 Health and Safety Practice',
	'NVQ L6 Site Management',
	'NVQ L7 Senior Management',
]


export const qualificationListClient = [
	{
		name: 'GQA',
		fullPrice: 340,
		half: false,
		installments:{}
	},
	{
		name: 'Tax Return',
		fullPrice: 100,
		half: false,
		installments:{}
	},
	{
		name: '18th edition',
		fullPrice: 500,
		half: false,
		installments:{}
	},
	{
		name: 'IPAF 3a 3b',
		fullPrice: 350,
		half: false,
		installments:{}
	},
	{
		name: 'ECS',
		fullPrice: 450,
		half: false,
		installments:{}
	},
	{
		name: 'AM2',
		fullPrice: 1560,
		half: false,
		installments:{}
	},
	{
		name: 'Pasma',
		fullPrice: 200,
		half: false,
		installments:{}
	},
	{
		name: 'Harness',
		fullPrice: 150,
		half: false,
		installments:{}
	},
	{
		name: 'Fire Marshal',
		fullPrice: 150,
		half: false,
		installments:{}
	},
	{
		name: 'Level 1 Award in Health and Safety in Construction',
		fullPrice: 150,
		half: false,
		installments:{}
	},
	{
		name: 'IOSH Working Safely Training Course',
		fullPrice: 150,
		half: false,
		installments:{}
	},
	{
		name: 'IOSH Managing Safely Training Course',
		fullPrice: 500,
		half: false,
		installments:{}
	},
	{
		name: 'L3 Award in First Aid at Work',
		fullPrice: 200,
		half: false,
		installments:{}
	},
	{
		name: 'CITB Site Manager Safety Training Scheme (SMSTS)',
		fullPrice: 550,
		half: false,
		installments:{}
	},
	{
		name: 'CITB Site Supervisors Safety Training Scheme (SSSTS)',
		fullPrice: 300,
		half: false,
		installments:{}
	},
	{
		name: 'NVQ L2 Bricklaying',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Demolition',
		fullPrice: 700,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Dry Lining Finishing',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Dry Lining Fixing',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Fitted Interiors',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Floor Screeding',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Formwork',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Groundworking',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},

	{
		name: 'NVQ L2 Hygienic Cladding',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Industrial Painting Decorating',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Painting Decorating',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Passive Fire Protection',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Plastering',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Carpenter - Shopfitting',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Site Carpentry - Wood Ocupation',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Wall & Floor Tiling',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Cladding',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Duckwork',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Multitrader',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Roofing',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Steel fixing',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Streetworks Operative',
		fullPrice: 600,
		half: false,
		installments:{
			id: LVL_2_SUB,
			deposit: LVL_2_DEP,
		}
	},
	{
		name: 'NVQ L2 Floorcovering',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	
	{
		name: 'NVQ L2 Steel erector',
		fullPrice: 750,
		half: true,
		installments:{}
	},
	// {
	// 	name: 'NVQ L2 Plumbing',
	// 	fullPrice: 2500,
	// 	half: true,
	// 	installments:{}
	// },
	{
		name: 'NVQ L2 Welding',
		fullPrice: 1500,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L2 Plumber - RO',
		fullPrice: 2000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L2 Plumber - UK',
		fullPrice: 3000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Bricklaying',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Formwork',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Occupational Supervision',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Painting Decorating',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Plastering',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Wall & Floor Tiling',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Wood Occupations',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Construction Contracting Operation',
		fullPrice: 1000,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L3 Electric',
		fullPrice: 2500,
		half: false,
		installments:{}
	},
	{
		name: 'NVQ L4 Construction Site Supervision',
		fullPrice: 1500,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L5 Health and Safety Practice',
		fullPrice: 1500,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L6 Contracting Operations',
		fullPrice: 1650,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L6 Health and Safety Practice',
		fullPrice: 2500,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L6 Site Management',
		fullPrice: 1650,
		half: true,
		installments:{}
	},
	{
		name: 'NVQ L7 Senior Management',
		fullPrice: 2000,
		half: true,
		installments:{}
	},




	
]



export const LANG = {
    ro:{
      email:'Email',
      password:'Parola',
      rePassword:'Reintroduce Parola',
      firstName:'Prenume',
      middleName:'Nume mijolciu',
      lastName:'Nume familie',
      dateOfBirth:'Data nasterii ex: DD/MM/YYYY',
      phoneNumber:'Numar telefon',
      address:'Adresa',
      postCode:'Cod postal',
      intermediar:'Inttermediar',
      nino:'NINO',
      cscs:'CSCS',
      qualification:'Calificare',
			addCard:'Adauga Card',
			username:'Username ex: ion423, alex50',
			houseNumber:'Numar casa'
    },
    eng:{
      email:'Email',
      password:'Password',
      rePassword:'Re Password',
      firstName:'First Name',
      middleName:'Middle Name',
      lastName:'Last Name',
      dateOfBirth:'Date of Birth ex: DD/MM/YYYY',
      phoneNumber:'Phone Number',
      address:'Address',
      postCode:'Post Code',
      intermediar:'Inttermediar',
      nino:'NINO',
      cscs:'CSCS',
      qualification:'Qualification',
			addCard:'Add Card',
			username:'Username ex: ion423, alex50',
			houseNumber:'House Number'
    }
}


