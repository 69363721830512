import React from 'react'
import { connect } from 'react-redux'


import { LANG } from '../../../../constants'

// actions
import {
  changeLanguage,
  handleStepsInputs,
  secondStepValidation
} from '../../../../actions/user'


class Step2 extends React.Component {



  handleInputChange = e => {
    
    this.props.handleStepsInputs( e )

  }

  handleOnNext = () => {

    this.props.secondStepValidation( )

  }

  componentDidMount() {

    let { currentStep } = this.props.register

    if ( currentStep < 2 )
      this.props.history.push('/register')
  }

  
  render() {

    let { defaultLang, isPending, 
      user :{ firstName, middleName, lastName, dateOfBirth, address, postCode, houseNumber }
    } = this.props.register

    return(
     <div className="box-form">

      <div className="flagsBox">
        <div className="flag" onClick={() => this.props.changeLanguage('ro')}>
          <img src="/assets/images/ro.png" alt='ro' />
        </div>

        <div className="flag" onClick={() => this.props.changeLanguage('eng')}>
          <img src="/assets/images/uk.png" alt='uk' />
        </div>

      </div>

      <div className="title">
        Register
      </div>

      <input 
        placeholder={LANG[defaultLang].firstName}
        type="text"
        name="firstName"
        autoComplete="off"
        value={firstName}
        maxLength={20}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].middleName}
        type="text"
        name="middleName"
        autoComplete="off"
        maxLength={20}
        value={middleName}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].lastName}
        type="text"
        name="lastName"
        maxLength={20}
        autoComplete="off"
        value={lastName}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].dateOfBirth}
        type="text"
        name="dateOfBirth"
        autoComplete="off"
        maxLength={10}
        value={dateOfBirth}
        onChange={this.handleInputChange}
      />
  
      <input 
        placeholder={LANG[defaultLang].address}
        type="text"
        name="address"
        autoComplete="off"
        maxLength={50}
        value={address}
        onChange={this.handleInputChange}
      />

       <input 
        placeholder={LANG[defaultLang].houseNumber}
        type="text"
        name="houseNumber"
        autoComplete="off"
        maxLength={10}
        value={houseNumber}
        onChange={this.handleInputChange}
      />

      <input 
        placeholder={LANG[defaultLang].postCode}
        type="text"
        name="postCode"
        autoComplete="off"
        maxLength={10}
        value={postCode}
        onChange={this.handleInputChange}
      />
  
      <button className="button" onClick={this.handleOnNext}>
        { isPending ? <i className="fad fa-spin fa-spinner"></i> : 'Next Step'}
      </button>

      <hr/>

      <div className="button-change" onClick={()=>this.props.history.push('/')}>
        Already a customer? Login
      </div>

     </div>
    )
  }
}

export default connect(
  state=>({
    user: state.user,
    register: state.register,
  }),{
    changeLanguage,
    handleStepsInputs,
    secondStepValidation
  }
) ( Step2 )