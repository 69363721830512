import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

import { FIREBASE_CONFIG } from '../constants'

firebase.initializeApp( FIREBASE_CONFIG )

/**
 * Firebase authentication method
 */
export const auth = firebase.auth()

/**
 * Firebase database method
 */
export const database = firebase.database()


/**
 * FireStore database method
 */
export const firestore = firebase.firestore()


/**
 * Firebase storage method
 */
export const storage = firebase.storage()

/**
 * Firebase server timestamp placeholder
 */
export const serverTime = firebase.firestore.FieldValue.serverTimestamp()

/**
 * Firebase timestamp
 */
export const timestamp = firebase.firestore.Timestamp


/**
 * Firestore Field Value 
 */
export const fieldValue = firebase.firestore.FieldValue

/**
 * Firebase Functions 
 */

 export const functions =  firebase.functions()