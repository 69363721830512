import React from 'react'
import { connect } from 'react-redux'
import { 
  Table,
  Button,

} from 'reactstrap';


//actions
import {
  fetchUsersTable,
  deleteUser
} from '../../../actions/admin'




class UserTable extends React.Component {
  

  handleOnDelete = user => {

    if ( window.confirm(`Do you want to delete the following user? ${user.firstName} ${user.middleName} ${user.lastName}`) ) {
       this.props.deleteUser( user.objectId, user.status )
    } 
  
  }

  renderRow = user => {
    let date = user.createdAt.toDate()
    return <tr key={ user.objectId } >
      <td className="center " > 
        <span className={ `round  ${user.status === 'pending' ? 'pending' : user.status === 'completed' ? "completed" : 'missing'} ` } />
      </td>
      <td className="min-display" onClick ={ ()=> this.props.history.push(`/${user.username}`) } > { user.firstName } </td>
      <td onClick ={ ()=> this.props.history.push(`/${user.username}`) } > { user.middleName } </td>
      <td className="min-display" onClick ={ ()=> this.props.history.push(`/${user.username}`)  } > { user.lastName } </td>
      <td> {  user.qualification[0] }</td>
      <td> { `${ date.getDate() }/${ date.getMonth() + 1 }/${ date.getFullYear() }`  }</td>
      
      <td  className="min-display" > 
        <div className="action center"  >
          <i className="fas fa-trash" onClick={ ()=> this.handleOnDelete( user ) } /> 
        </div>
      </td>
    </tr>
  }
  
  render() {
    return(
      <React.Fragment>

        
        <Table>
          <thead>
            <tr>
              <th className=""> Status </th>
              <th className="min-display"> First name </th>
              <th> Middle Name </th>
              <th className="min-display"> Last Name </th>
              <th> Qualification </th>
              <th> Registered Date </th>
              <th className="min-display"> Action </th>
            </tr> 
          </thead>

          <tbody>
            
            { Object.values( this.props.data ).sort((a,b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0)).map( user => {
                return this.renderRow( user )
              })
              
            }
            
          </tbody>

        </Table>

        <div className="pagination">
          { this.props.pageHistory.back ?
            <Button outline  color="info" onClick={ ()=>  this.props.fetchUsersTable( 'back' ) }>
               <i className="fas fa-chevron-left" />  Back
            </Button>
          :
            <span></span>
          }
          
          { !this.props.lastPage ?
            <Button outline color="info" onClick={ ()=>  this.props.fetchUsersTable( 'next' ) }>
              Next   <i className="fas fa-chevron-right" /> 
            </Button>
          :
            <span></span>
          }

        </div>      
        
       
      
      </React.Fragment>
    )
  }
}

export default connect(
  state=>({
   
  }),{
    fetchUsersTable,
    deleteUser
  } 
) ( UserTable )