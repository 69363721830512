
const initialState = {
	list:{}
}

export default (state = initialState, action) => {
	switch ( action.type ) {
		case 'PROFILE_LOADED':

			if ( !action.payload.objectId )
				return state
		
			return {
				...state,
				[ action.payload.objectId ]: {
					...state[ action.payload.objectId ],
					...action.payload
				},

				list: {
					...state.list,
					[ action.payload.username ]: action.payload.objectId
				}
			}

		case 'PROFILE_NOTES_LOADED':
			return {
				...state,
				[ action.payload.objectId ]: {
					...state[ action.payload.objectId ],
					notes: action.payload.notes
				},

			}	

	
			
		case 'PROFILE_LOADING': 
			return {
				...state,
				[ action.payload ]: null
			}
		default:
			return state
	}
}