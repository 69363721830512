const initialState = {
  isPending:true
}

export default ( state = initialState, action ) => {

  switch ( action.type ) {
  
    case 'STATISTICS_LOADED':
      return action.paylaod
    
    default:
      return state
  } 
}