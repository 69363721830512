import React from 'react'
import { connect } from 'react-redux'

class Note extends React.Component {

  render() {

    let { message, status, authorId, userId, createdAt } = this.props.data
    createdAt =  new Date( createdAt )
    let { profiles, history } = this.props

    return(
      
      <div className="note no-margin">
       
        <div 
          className={`buble ${ status === 'important' ? 'important' : status === 'pending' ? 'pending' : status === 'completed' ? 'completed': '' } `}
        >
          
          <div className='home-buble-header'>
             <span onClick ={ ()=> history.push(`/${profiles[userId].username}`) }  > { profiles[userId] ?  profiles[userId].firstName + ' ' +  profiles[userId].lastName   : ' Loading ..' } </span>
            <span> { `${createdAt.getDate()}/${createdAt.getMonth()+1}/${createdAt.getFullYear()}` } </span>
          </div>
          
          <div  dangerouslySetInnerHTML={{__html: message }} /> 

          <div className='home-buble-footer'>
            <span> Created by  { profiles[authorId] ?  profiles[authorId].firstName + ' ' +  profiles[authorId].lastName   : ' Loading ..' } </span> 
          </div>

        </div>

          
       
      </div>
    )
  }
}

export default connect(
  state=>({
    profiles: state.profiles
  })
)( Note )