import React from 'react'
import { connect } from 'react-redux';
import {  NavLink } from 'react-router-dom'

// Actions
import{ logout } from '../../actions/user'

class SideMenu extends React.Component {

  state={
    show: false,
  }

  render(){
    
    let { user:{ isAdmin, objectId }, profiles } = this.props
    let { show } = this.state
    let self = profiles[ objectId ]

    return(
      <div id="sideMenu">

        <div className='user_info'>
          <span> { self.firstName }{  self.middleName ? ' ' + self.middleName+ ' ' : ' ' }{ self.lastName } </span>
          <div className="menu-toggle" onClick={ ()=> this.setState({ show: !show })}>
            <i className={`far fa-${ show ? 'times': 'bars'}`}/>
          </div>
        </div> 

        <div className={`buttons-group ${ show ? "display": "" }`}>

        { !isAdmin && 
            <NavLink 
              to={ `/${self.username}` } 
              className={ "sideBtn" }
              activeClassName={ "active" }
              exact
            >
              <i className="fas fa-user"/>
              <span>  Profile </span> 
            </NavLink> 
          }

          { isAdmin &&
            <NavLink 
              to={ '/' } 
              className={ "sideBtn" }
              activeClassName={ "active" }
              exact
            >
              <i className="fas fa-analytics"/>
              <span>  Dashboard </span> 
            </NavLink> 
          }
          
          { isAdmin &&
            <React.Fragment>

              <NavLink 
                to={ '/users' } 
                className={ "sideBtn" }
                activeClassName={ "active" }
              >
                <i className="fad fa-users"/>
                <span>  Clients List </span> 
              </NavLink>


            </React.Fragment>
          }
          

          <div 
            className={`sideBtn logout` }
            onClick={ ()=> this.props.logout() } 
          >
            <i className="fas fa-sign-out-alt"/>
            <span>  Logout </span> 
          </div>

        </div>

        
      </div>
    )
  }
}

export default connect( 
  state=>({
    user: state.user,
    profiles: state.profiles
  }),{
    logout
  }
) ( SideMenu )