import React from 'react'
import { connect } from 'react-redux'

import { 
  Button
} from 'reactstrap';

import { updateNoteStatus } from '../../../../actions/admin'


class Note extends React.Component {

  render() {
    let { message, createdAt, editedAt, authorId, status, editedBy  } = this.props.note

    let { profiles } = this.props
    let author = profiles[ authorId ]

    createdAt =  new Date( createdAt )

    if( editedAt )
      editedAt =  new Date( editedAt )


    if( editedBy )
      editedBy = profiles[ editedBy ]  

    return(
      <div className="note">
        
        <div className="note-info">
          <span className="author"> { author ? `${author.firstName} ${author.lastName}` : 'Loading ...'} </span>

          <span> { `${createdAt.getDate()}/${createdAt.getMonth()+1}/${createdAt.getFullYear()}` } </span>
          
             <Button 
              outline 
              color="info" 
              onClick={ ()=> this.props.onEdit( this.props.note ) } 
            > 
                Edit 
            </Button>  
          

        </div>

        <div className={`buble ${ status === 'important' ? 'important' : status === 'pending' ? 'pending' : status === 'completed' ? 'completed': '' } `}>
          <div dangerouslySetInnerHTML={{__html: message }} />  
          { editedAt  && 
             <div className="footer">
              Edited {  editedBy && `by ${ editedBy.firstName}${' ' + editedBy.middleName + ' '}${ editedBy.lastName } on `  } { `${editedAt.getDate()}/${editedAt.getMonth()+1}/${editedAt.getFullYear()}` }
            </div>
          }
        
         
        </div>
      </div>  
         
        
   
     
    )
  }

}


export default connect(
  state=>({
    profiles: state.profiles
  }),{
    updateNoteStatus
  }
) ( Note )