import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { 
  InputGroup,
  InputGroupAddon,
  Input,
  Button,

} from 'reactstrap';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { 
  DateRangePicker,
  SingleDatePicker
} from 'react-dates';

//Templetes 
import CreateUser from '../Forms/createUser'
import SideMenu  from '../../SideMenu'
import UserTable from '../Forms/userTable'

//actions
import { 
  fetchUsersTable,
  searchUser,
  displayCreateUser,
  viewUser,
  test
} from '../../../actions/admin'

import { qualificationList } from '../../../constants'

class Users extends React.Component {
  

 

  state = {
    search:'',
    searchBy:'firstName',
    date: null,
    focused: null
  }

  

  

  handleChange = e => {

    let { name, value } = e.target

    if( name === 'searchBy' )
      this.setState({
        search: '',
      })

    this.setState({
      [name]: value,
    })
    
  }


  onSearch = ( e ) => {
    
 
    if ( e.key !== 'Enter' && e.key !== undefined  ) 
      return
    

    let { startDate, endDate, search, searchBy, date } = this.state
    let val = search; 

    
  
    if ( searchBy === 'status') 
      val = {
        status:search,
        startDate: startDate ?  startDate.hour(0).toDate() : '' ,
        endDate:  endDate ? endDate.hour(23).minute(59).toDate() : '',
      }

    if ( searchBy === 'dateOfBirth') {
      date = date.toDate()
      let day = date.getDate()
      let mointh = date.getMonth()+1
      let year = date.getFullYear()
      
      val = `${day < 10 ? '0'+day : day }/${mointh < 10 ? '0'+mointh : mointh}/${year}`

    }
      
      

    this.props.searchUser( val, searchBy )

  }
  
  componentDidMount() {
    this.props.fetchUsersTable()
    //this.props.test()
  
  }

  render(){
    //console.log( this.state)
    let { searchBy, search } = this.state
    let { displayCreateUser } = this.props.utils

    return(
      <div className='dashboard'>

          <Route component={SideMenu} />

          <div className="detail">
      
        <div id='users' className='box' >

     
          <div className="header">

            <div className="title">
              <i className="fas fa-user"/>
              <span> USERS </span>
            </div>

            <div className="buttons-group">
              <Button 
                outline 
                color="info"
                onClick={ ()=> this.props.displayCreateUser( true ) }
              >
                <i className="fas fa-plus" /> Create User 
              </Button>
                
            </div>

          </div>

          <br/>

          <InputGroup>

            <InputGroupAddon addonType="prepend">
              <Input 
                type="select"
                value={ searchBy }
                name="searchBy"  
                onChange={ this.handleChange }
              >
                <option value="firstName" >First Name</option>
                <option value="lastName">Last Name </option>
                <option value="dateOfBirth">Date Of Birth</option>
                <option value="phoneNumber">Phone Number</option>
                <option value="intermediar">Intermediar</option>
                <option value="qualification">Qualification</option>
                <option value="status">Status</option>
                
                
              </Input>
            </InputGroupAddon>
           
            
            { searchBy !== 'status' ?

              searchBy === 'qualification' ?
              <Input 
                type="select"
                value={ search }
                name="search"  
                onChange={ this.handleChange }
              >
                <option value="" disabled defaultValue >Pick Qualification</option>
                
                { qualificationList.map( item => {
                    return   <option key={ item } value={ item } > { item } </option>
                  })
                }
              
              </Input>
              :
              searchBy === 'dateOfBirth' ?

                <SingleDatePicker
                  date={this.state.date} // momentPropTypes.momentObj or null
                  onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                  isOutsideRange={() => false}
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  displayFormat="DD/MM/YYYY"
                  id="your_unique_id" // PropTypes.string.isRequired,
                />

              :
            
                <Input
                  onKeyDown={ this.onSearch }
                  placeholder='Serach ...'
                  name="search"  
                  onChange={ this.handleChange }
                />
            : 
              <React.Fragment>
              <Input 
                type="select"
                value={ search }
                name="search"  
                onChange={ this.handleChange }
              >
                <option value="" disabled defaultValue >Pick status</option>
                <option value="missing" > Missing </option>
                <option value="pending"> Pending </option>
                <option value="completed"> Completed </option>
              </Input>
                

              <DateRangePicker
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                startDate={this.state.startDate} 
                endDate={this.state.endDate}
                startDateId="your_unique_start_date_id"
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate:startDate, endDate })} 
                focusedInput={this.state.focusedInput} 
                onFocusChange={focusedInput => this.setState({ focusedInput })} 
              />
            </React.Fragment>
            
            }

            <InputGroupAddon addonType="append">
              <Button 
                outline 
                color="info"
                onClick={ this.onSearch }
              > 
                <i className="fas fa-search" /> Search 
              </Button>
            </InputGroupAddon>
            
          </InputGroup>

 
          <div className="content">

            { this.props.utils.userTablePending ?
              <div className="spinnerBox">
                <i className="fas fa-spinner fa-spin" />
              </div>
              
              :
                < UserTable 
                  data ={ this.props.utils.tableUsers } 
                  pageHistory={ this.props.utils.pageHistory }
                  lastPage = {this.props.utils.lastPage }
                  history={ this.props.history }
                
                />

            }

          </div>

          { displayCreateUser &&
            <CreateUser />
          }

          
          
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state=>({
    profiles: state.profiles,
    utils: state.utils,
    user: state.user
  }),{
    fetchUsersTable,
    searchUser,
    displayCreateUser,
    viewUser,
    test
  }
) ( Users )