const initialState = {
	history: null,
	isPending: true,

}

export default function( state = initialState, action ) {
	switch ( action.type ) {
		case 'USER_ACTION_PENDING':
			return {
				...state,
				isPending: true
			}
		case 'USER_LOGGED_IN':
			return {
				...state,
				isPending: false,
				...action.payload
			}
		case 'USER_LOGGED_OUT':
			return {
				...initialState,
				isPending: false
			}
		case 'USER_ACTION_ENDED':
			return {
				...state,
				isPending: false
			}
	
		default:
			return state
	}
}