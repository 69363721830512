import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Login from './Login'
import Register from './Register'

import Welcome from '../Admin/Welcome'

class Home extends Component {


  render() {

    return (
			<div id="home">

        { this.props.user.objectId ?
          <Route component={Welcome} />
          :
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/register" component={Register} />		
          </Switch>
        }
				
			</div>
    )
  }
}

export default connect(
	state => ({ 
		user: state.user 
	})
)(Home)